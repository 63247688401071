import React, { useState } from "react";
import ImageUploader from "../Notation/ImageUploader";
import ImageManager from "../Notation/ImageManager";
import "././../../Assets/Styles/ImageAnotation.css";

function ImageAnotation() {

  return (
    <div>
      <ImageManager />
    </div>
  );
}

export default ImageAnotation;
