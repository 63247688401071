import React from 'react';
import './SkeletonLoader.css'; // Import your CSS for styling

const SkeletonLoader = () => {
  return (
    <div className="skeleton-dashboard">
      <div className="skeleton-navbar"></div>
      <div className="skeleton-content">
        <div className="skeleton-sidebar"></div>
        <div className="skeleton-main">
          
          <div className="skeleton-main-body">
            <div className="skeleton-card">
             
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
