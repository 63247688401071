import React, { useEffect, useState } from "react";
import "../../Assets/Styles/liveview.css";
import VideoStream from "../../pages/LiveView/LiveFeed"; // Adjust the import path
import api from "../../Components/Utils/api";

const LiveView = () => {
  const [grid, setGrid] = useState(3);
  const [error, setError] = useState("");
  const [wsUrls, setWsUrl] = useState([]);


  const storedDevice = JSON.parse(localStorage.getItem("activeDeviceData"));

  const rtspUrls = storedDevice?.map((device ,index)=> ({
    rtspUrl: device.url,
    wsPort:10000+index
  }));

  const handleGrid = () => {
    setGrid((prevGrid) => (prevGrid === 3 ? 1 : prevGrid + 1));
  };

  const setInitialGridValue = () => {
    if (window.innerWidth < 600) {
      setGrid(1);
    } else {
      setGrid(3);
    }
  };

  useEffect(() => {
    setInitialGridValue();

    const handleResize = () => {
      setInitialGridValue();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const multiStreams = async () => {
    try {
      const response = await api.post(
        `/api/stream/startall`,
        {
          rtsp: rtspUrls
        }
      );
      setWsUrl(response.data.urls);
      setError("");
    if(response.status=== 500){
      setError("Intenal server error");
      console.log("Intenal server error");
    }
    } catch (error) {
      setError("Error starting streams");
    }
  };

  const closeMultiStreams = async () => {
    try {
      const response = await api.post(
        `/api/stream/stopall`
      );

      console.log(response.data.message);
      setError("");
    } catch (error) {
      setError("Error starting streams");
    }
  };

  useEffect(() => {
    multiStreams();
    getGridClass();
    
    return () => {
      // Cleanup operations
      // For example, if multiStreams starts a stream or subscribes to something,
      // you would stop the stream or unsubscribe here.
      // cleanupMultiStreams();
      // cleanupGridClass();
    };
    
  }, []);

  useEffect(() => {
    getGridClass();
  }, [grid]);

  const getGridClass = () => {
    switch (grid) {
      case 1:
        return "livecards-1";
      case 2:
        return "livecards-2";
      case 3:
        return "livecards-3";
      default:
        return "livecards-1";
    }
  };

  return (
    <div className="LiveView">
      <div className="liveView-title">
        <h3>LiveView</h3>
        <button onClick={handleGrid}>Grid{grid}</button>
      </div>
      <div className="multipleStream">
        <div className={`livecards ${getGridClass()}`}>
          {error && <p>{error}</p>}
          {wsUrls.map(({ rtspUrl, wsUrl }) => (
            <VideoStream key={rtspUrl} rtspUrl={rtspUrl} wsUrl={wsUrl} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LiveView;
