import React from "react";

import "../../Assets/Styles/InstructionsModal.css";
import { useEffect } from "react";
import { IoMdClose } from "react-icons/io";

const InstructionsModal = ({ setIsInstructionsOpen }) => {
  useEffect(() => {
    setIsInstructionsOpen(true);
  }, []);

  return (
    <div className="instruction-modal">
      <div className="ReactModal__Content">
        <button onClick={() => setIsInstructionsOpen(false)}>
          <IoMdClose className="close-butt" />
        </button>
        <h2>Instructions for Using the Project Management Interface</h2>
        <p>
          <strong>Overview:</strong> This interface allows you to create and
          manage your projects efficiently. You can either create a new project
          using existing data or build a new project from scratch.
        </p>
        <ol>
          <li>
            <strong>Open the Interface:</strong> Navigate to the interface where
            you can manage your projects. This will typically be provided as a
            part of a web application.
          </li>
          <li>
            <strong>Enter Project Name:</strong> Locate the input field labeled
            "Project Name". Click inside the input field and type the name of
            your project.
          </li>
          <li>
            <strong>Choose an Action:</strong> You have two buttons:{" "}
            <strong>Create</strong> and <strong>Build</strong>.
            <ul>
              <li>
                <strong>Create:</strong> This button is used to create a project
                using existing data. Hover over the button to see additional
                information. Click on the "Create" button to initiate the
                process.
              </li>
              <li>
                <strong>Build:</strong> This button is used to build a new
                project with your own data. Hover over the button to see
                additional information. Click on the "Build" button to start
                building a new project.
              </li>
            </ul>
          </li>
          <li>
            <strong>Tooltip Information:</strong> When you hover over each
            button, a tooltip will appear providing additional information about
            the button's functionality.
          </li>
          <li>
            <strong>After Clicking the Button:</strong>
            <ul>
              <li>
                <strong>For Create:</strong> The system will use the existing
                data to create your project. You might be prompted to provide
                additional details or configurations.
              </li>
              <li>
                <strong>For Build:</strong> You will be guided through a process
                to input or upload new data for your project. Follow the
                on-screen instructions to complete the process.
              </li>
            </ul>
          </li>
          <li>
            <strong>Completion:</strong> Once the process is complete, you will
            receive a confirmation message. Your project will be listed in your
            project management dashboard or interface.
          </li>
        </ol>
        <p>
          <strong>Important Notes:</strong> Ensure that the project name is
          unique. This helps avoid conflicts with existing projects. Hover over
          the buttons to get helpful information about what each button does.
          Follow on-screen instructions carefully, especially when building a
          new project, to provide all necessary information and data.
        </p>
      </div>
    </div>
  );
};

export default InstructionsModal;
