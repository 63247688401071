import React, { useContext, useEffect, useState } from "react";
import Draggable from "react-draggable";
import "./ModelNotificationCard.css"; // Optional: for custom styling
import { MdClose } from "react-icons/md";
import { FaRedo, FaStop } from "react-icons/fa";
import { MdCancelScheduleSend } from "react-icons/md";
import { UserContext } from "../../ContextApi/MyContext";
import api from "../Utils/api";
import useFeatureExists from "../CustomHooks/useCheckFeture";
import { IoIosResize } from "react-icons/io";
import { RxWidth } from "react-icons/rx";

const ModelNotificationCard = ({ title }) => {
  const [trainingStatus, setTrainingStatus] = useState({});
  const [cardVisible, setCardVisible] = useState(true);
  const [trainedData, setTrainedData] = useState();
  const [dimensions, setDimensions] = useState({ width: 300, height: 400 });
  const { clientData, clientfeatures } = useContext(UserContext);
  const { checkFeatureExists } = useFeatureExists(clientfeatures);
  const hasAnotationFeature = checkFeatureExists("annotation");

  const clientName = clientData ? clientData.clientName : null;

  const handleStartStop = async (status, project_id) => {
    const modeldata = trainedData.find(
      (item) => item.project_id === project_id
    );
    const newTrainingStatus = { ...trainingStatus };
    try {
      await api.post(`/api/annotation/${clientName}/changetrainingstatus`, {
        project_name: modeldata.project_name,
        client_name: clientName,
        model_name: modeldata.modelName,
        operation: status,
        pythonApi: process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL,
        project_id: project_id,
      });
      newTrainingStatus[project_id] = status !== "stop";
    } catch (error) {
      console.error("Error changing training status:", error.message);
    }
    setTrainingStatus(newTrainingStatus);
  };

  const handleCardVisibleClose = () => {
    setCardVisible(false);
  };

  useEffect(() => {
    const GetTrainModelData = async () => {
      try {
        const response = await api.get(
          `/api/annotation/${clientName}/getTrainModelData`
        );
        const data = response.data.filter(
          (item) => item.operation === "start" || item.operation === "stop"
        );
        const initialStatus = {};
        data.forEach((item) => {
          initialStatus[item.project_id] = item.operation === "start";
        });
        setTrainingStatus(initialStatus);
        setTrainedData(data);
      } catch (error) {
        console.error("Error Fetching Train Model Data:", error.message);
      }
    };
    GetTrainModelData();
  }, [clientName]);

  const handleResize = (e) => {
    e.preventDefault();
    const newWidth = Math.max(
      200,
      e.clientX - e.target.getBoundingClientRect().left
    );
    const newHeight = Math.max(
      100,
      e.clientY - e.target.getBoundingClientRect().top
    );
    setDimensions({ width: newWidth, height: newHeight });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const initialWidth = dimensions.width;
    const initialHeight = dimensions.height;
    const startX = e.clientX;
    const startY = e.clientY;

    const handleMouseMove = (moveEvent) => {
      const newWidth = Math.max(
        200,
        initialWidth + (moveEvent.clientX - startX)
      );
      const newHeight = Math.max(
        100,
        initialHeight + (moveEvent.clientY - startY)
      );
      setDimensions({ width: newWidth, height: newHeight });
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <>
      {cardVisible && hasAnotationFeature && (
        <Draggable bounds="parent">
          <div
            className="modelTraining-card"
            style={{ width: dimensions.width, height: dimensions.height }}
          >
            <div className="card-header">
              <h4>{title}</h4>
              <MdClose
                className="close-icon"
                onClick={handleCardVisibleClose}
              />
            </div>

            <div className="scrollable-content">
              {trainedData?.map((item) => (
                <React.Fragment key={item.project_id}>
                  <div className="card-body">
                    {trainingStatus[item.project_id] ? (
                      <div className="training-animation">
                        <div className="spinner"></div>
                        <p>Training is starting...</p>
                      </div>
                    ) : (
                      <p>Training is stopped.</p>
                    )}

                    <div className="card-footer">
                      <div>
                        <h1>Project Name: {item.project_name}</h1>
                        <h3>Model Name: {item.modelName}</h3>
                      </div>
                      {!trainingStatus[item.project_id] ? (
                        <button
                          onClick={() =>
                            handleStartStop("restart", item.project_id)
                          }
                        >
                          <FaRedo title="Restart" />
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            handleStartStop("stop", item.project_id)
                          }
                        >
                          <FaStop title="Stop" />
                        </button>
                      )}
                      <button
                        onClick={() =>
                          handleStartStop("cancel", item.project_id)
                        }
                      >
                        <MdCancelScheduleSend title="Cancel Training" />
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>

            {/* Resize Handle */}
            <div className="resize-handle" />
            <RxWidth
              onMouseDown={handleMouseDown}
              className="resize-icon"
            />
          </div>
        </Draggable>
      )}
    </>
  );
};

export default ModelNotificationCard;
