import React from "react";
import { Link } from "react-router-dom";
import img from "../../Assets/Images/business-success-report-graph-concept.png";

const GrowWithUs = () => {
  return (
    <div className="content3">
      <div className="content3__container">
        <h2>Grow With Us:</h2>
        <Link to="/" className="image__link ">
          <img src={img} alt="Grow with us Image" className="growth" />
        </Link>
        <p>
          At BuyAISolution, we see growth as a shared journey. Our AI solutions
          evolve with your business, offering the tools to thrive in a dynamic
          world. Partnering with us means embracing a future where your success
          is ours. Together, let’s harness AI to empower your team, enhance
          operations, and drive your business to new heights.
        </p>
      </div>
    </div>
  );
};

export default GrowWithUs;
