import React from "react";
import { NavLink } from "react-router-dom";
import "../../Assets/Styles/BottomNavBar.css";
import { FaHome, FaInfoCircle, FaServicestack, FaProductHunt, FaQuestion } from "react-icons/fa";

const BottomNavBar = () => {
  return (
    <div className="bottomnavbar">
      <NavLink to="/" className="bottomnavbar__link" activeclassname="active">
        <FaHome className="bottomnavIcon"/>
        <span>Home</span>
      </NavLink>
      <NavLink to="/aboutus" className="bottomnavbar__link" activeclassname="active">
        <FaInfoCircle className="bottomnavIcon"/>
        <span>About</span>
      </NavLink>
      <NavLink to="/ourservice" className="bottomnavbar__link" activeclassname="active">
        <FaServicestack className="bottomnavIcon"/>
        <span>Services</span>
      </NavLink>
      <NavLink to="/product" className="bottomnavbar__link" activeclassname="active">
        <FaProductHunt className="bottomnavIcon"/>
        <span>Product</span>
      </NavLink>
      <NavLink to="/whybuyaisolution" className="bottomnavbar__link" activeclassname="active">
        <FaQuestion className="bottomnavIcon"/>
        <span>Why Us?</span>
      </NavLink>
    </div>
  );
};

export default BottomNavBar;
