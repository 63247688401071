import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsCloudUpload } from "react-icons/bs";

const IMAGE_WIDTH = 640;
const IMAGE_HEIGHT = 640;

function ImageUploader({ setImages }) {
  const [loading, setLoading] = useState(false);
  const resizeImage = (file, width, height) => {
    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const aspectRatio = img.width / img.height;
          let newWidth, newHeight;
          if (img.width > img.height) {
            newWidth = width;
            newHeight = width / aspectRatio;
          } else {
            newHeight = height;
            newWidth = height * aspectRatio;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          const dataUrl = canvas.toDataURL(file.type);
          resolve({ src: dataUrl, name: file.name, width: newWidth, height: newHeight });
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true); // Set loading to true when upload starts

    const imagePromises = acceptedFiles
      .filter((file) => file.type.startsWith("image/"))
      .map((file) => resizeImage(file, IMAGE_WIDTH, IMAGE_HEIGHT));

    Promise.all(imagePromises).then((images) => {
      setImages(images);
      setLoading(false); // Set loading to false when upload finishes
    });
  }, [setImages]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  return (
    <div className="konvajs-content imageUpload" style={{ position: "relative", width: "100%" }}>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <div animation="border" role="status">
              <div className="imguploderspinner"></div>
            </div>
            <p>Uploading and resizing images...</p>
          </div>
        ) : (
          <>
            <BsCloudUpload size={72} />
            <br />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </>
        )}
      </div>
    </div>
  );
}

export default ImageUploader;
