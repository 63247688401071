import React, { useEffect } from "react";

import "../../Assets/Styles/notification.css";
import DetectedViewCard from "../../Components/Reports/DtectedViewCard";
import useLastAlertData from "../../Components/CustomHooks/useLastAlertData";
const LatestAlert = () => {
  console.log('........................')
  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientName = clientData ? clientData.clientName : null;
  const {aData, getLastAlertData}=useLastAlertData(clientName);
  
  
  useEffect(() => {
      getLastAlertData();
    }, []);

  return (

    <div className="notifiction__page">
      <h1>Latest Process Alert</h1>
          <div>
            <DetectedViewCard alertData={aData} clientName={clientName} />
          </div>
    </div>
  );
};

export default LatestAlert;
