import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../../../Assets/Styles/sidebar.css"; // Import your CSS file
import { GoAlert, GoDeviceCameraVideo } from "react-icons/go";
import { MdFeaturedPlayList, MdLabel } from "react-icons/md";
import { VscServerProcess } from "react-icons/vsc";
import {
  TbDeviceImacStar,
  TbReportAnalytics,
  TbAlertSquareRoundedFilled,
} from "react-icons/tb";
import { UserContext } from "../../../ContextApi/MyContext";
import useFeatureExists from "../../CustomHooks/useCheckFeture";
import ReportList from "../../Reports/ReportList";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { FaChartBar, FaUserSlash, FaUsers } from "react-icons/fa";
import { AiTwotoneAlert } from "react-icons/ai";
import { useOutletContext } from "react-router-dom";

const Sidebar = ({clientRole}) => {

  const [activeLink, setActiveLink] = useState(null);
  const [isShowList, setIsShowList] = useState(false);
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const { clientfeatures } = useContext(UserContext);
  const { checkFeatureExists } = useFeatureExists(clientfeatures);
  const hasAnotationFeature = checkFeatureExists("annotation");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const hideShowList = () => {
    setIsShowList(!isShowList);
  };

  const list = [
    // {
    //   id: 1,
    //   path: "reports/livealerts",
    //   name: "Live Alerts",
    //   icon: <TbAlertSquareRoundedFilled className="nav__Icon" />,
    // },
    // {
    //   id: 2,
    //   path: "reports/allalerts",
    //   name: "All Alerts",
    //   icon: <AiTwotoneAlert className="nav__Icon" />,
    // },
    // {
    //   id: 3,
    //   path: "reports/processhistory",
    //   name: "Process History",
    //   icon: <VscServerProcess className="nav__Icon" />,
    // },
    // {
    //   id: 4,
    //   path: "reports/charts",
    //   name: "Charts",
    //   icon: <FaChartBar className="nav__Icon" />,
    // },
    {
      id: 5,
      path: "reports/totalpeoplecount",
      name: "Total People Count",
      icon: <FaUsers className="nav__Icon" />,
    },
    {
      id: 6,
      path: "reports/inpeoplecount",
      name: "In People Count",
      icon: <FaUsers className="nav__Icon" />,
    },
    {
      id: 7,
      path: "reports/outpeoplecount",
      name: "Out People Count",
      icon: <FaUsers className="nav__Icon" />,
    },
    {
      id: 8,
      path: "reports/out2peoplecount",
      name: "Out2 People Count",
      icon: <FaUsers className="nav__Icon" />,
    },
    // {
    //   id: 6,
    //   path: "reports/charts",
    //   name: "Unmatched People",
    //   icon: <FaUserSlash className="nav__Icon" />,
    // },
  ];

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    setActiveLink(pathArray[3]);
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 2) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  return (
    <>
    {clientRole === 'user' && <div className={isSticky ? "navbar sticky" : "navbar"}>
    <div className="link-list">
      {/* <NavLink
        to="/dashboard"
        className={`navLink ${activeLink === "dashboard" ? "active" : ""}`}
        onClick={() => handleLinkClick("dashboard")}
      >
        <TbDeviceImacStar className="nav__Icon" /> Device Management
      </NavLink>
      <NavLink
        to="features"
        className={`navLink ${activeLink === "objectcount" ? "active" : ""}`}
        onClick={() => handleLinkClick("objectcount")}
      >
        <MdFeaturedPlayList className="nav__Icon" /> Features Mapping
      </NavLink>
      <NavLink
        to="live"
        className={`navLink ${activeLink === "live" ? "active" : ""}`}
        onClick={() => handleLinkClick("live")}
      >
        <GoDeviceCameraVideo className="nav__Icon" /> Live View
      </NavLink>
      <NavLink
        to="alertsetting"
        className={`navLink ${activeLink === "alertsetting" ? "active" : ""}`}
        onClick={() => handleLinkClick("alertsetting")}
      >
        <GoAlert className="nav__Icon" />
        Alert Management
      </NavLink> */}
      {/* {hasAnotationFeature && (
        <NavLink
          to="annotationandlabelling"
          className={`navLink ${
            activeLink === "annotationandlabelling" ? "active" : ""
          }`}
          onClick={() => handleLinkClick("annotationandlabelling")}
        >
          <MdLabel className="nav__Icon" />
          Annotation
        </NavLink>
      )} */}
      <NavLink
        to="/dashboard"
        className={`navLink ${activeLink === "dashboard" ? "active" : ""}`}
        onClick={() => handleLinkClick("dashboard")}
      >
        <TbDeviceImacStar className="nav__Icon" /> DashBoard
      </NavLink>
      <div
        className={`navLink ${activeLink === "report" ? "active" : ""}`}
        onClick={hideShowList}
      >
        <TbReportAnalytics className="nav__Icon" />
        Reports
        {!isShowList ? (
          <span>
            <SlArrowDown />
          </span>
        ) : (
          <span>
            <SlArrowUp />
          </span>
        )}
      </div>
      <div style={{ width: "100%" }}>
        {isShowList && (
          <ReportList
            list={list}
            activeLink={activeLink}
            handleLinkClick={handleLinkClick}
          />
        )}
      </div>
    </div>
  </div>}
    </>
    
  );
};

export default Sidebar;
