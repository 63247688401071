
import React from "react";
import "../../Assets/Styles/Loader.css"; // Assuming you have some basic CSS for the loader

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
