import React from "react";

function AnnotationList({
  annotations,
  handleSendData,
  label,
  modelName,
  handleLabelChange,
  handleModelChange,
  loading
}) {
  return (
    <div className="annotation-list">
      <div className="annotation_title">
        <h3>Annotations</h3>
        <input
          type="text"
          required
          placeholder="Enter Model Name"
          value={modelName}
          onChange={handleModelChange}
        />
      </div>
      <ol className="anotation__list_item">
        {annotations?.map((annotation, index) => (
          <>
            <li key={annotation.id}>
              {annotation?.label || label} &nbsp;
              {annotation?.x_center.toFixed(4)},&nbsp;
              {annotation?.y_center.toFixed(4)},&nbsp;
              {annotation?.width.toFixed(4)},&nbsp;
              {annotation?.height.toFixed(4)}
            </li>
          </>
        ))}
      </ol>

      <input
        type="text"
        value={label}
        onChange={handleLabelChange}
        placeholder="Enter label"
        autocomplete="on"
      />
      <div className="annotation__button">
        <button onClick={handleSendData}>
          {loading ? "Sending....." : "Send Data"}
        </button>{" "}
      </div>
    </div>
  );
}

export default AnnotationList;
