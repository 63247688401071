// FeatureCard.js
import React from "react";
import { motion } from "framer-motion";

const FeatureCard = ({ imgSrc, altText, title, text, isTextLeft }) => (
  <>
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      className="contents"
    >
      {isTextLeft ? (
        <>
          <div className="text textl">
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
          <div className="imagesr">
            <img src={imgSrc} alt={altText} className="imagesr__img" />
          </div>
        </>
      ) : (
        <>
          <div className="imagesr">
            <img src={imgSrc} alt={altText} className="imagesr__img" />
          </div>
          <div className="text textl">
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
        </>
      )}
    </motion.div>

    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      className="contents__box"
    >
      <div className="imagesrM">
        <img src={imgSrc} alt={altText} className="imagesr__img" />
      </div>
      <div className="text textl">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </motion.div>
  </>
);

export default FeatureCard;
