import React, { useContext, useEffect, useState } from "react";
import "../../Assets/Styles/features.css";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../ContextApi/MyContext";
import useFeatureExists from "../../Components/CustomHooks/useCheckFeture";
import AnprGuideCard from "../../Components/GuideComponents/AnprGuideCard";
import SmartGuide from "../../Components/GuideComponents/SmartGuide";
import PeapleGuide from "../../Components/GuideComponents/PeapleGuide";

const Features = () => {
  const [activeLink, setActiveLink] = useState(null);
  // Hook to get the current route location
  const location = useLocation();

  // Get client features from UserContext
  const { clientfeatures } = useContext(UserContext);

  // Custom hook to check if a feature exists for the user
  const { checkFeatureExists } = useFeatureExists(clientfeatures);

  // Regex to match routes inside ANPR or SmartBox with IDs
  const insideAnprOrOtd =
    /^\/dashboard\/features\/(objectcount|anpr|smartbox)\/[0-9]+/;

  const outsideAnprOrOtd =
    /^\/dashboard\/features\/(objectcount|anpr|smartbox)+/;
  const navigate = useNavigate();
  // Check if specific features are available for the user
  const hasAnprFeature = checkFeatureExists("anpr");
  const hasObjectFeature = checkFeatureExists("objectcount");
  const hasSmartFeature = checkFeatureExists("smartbox");
  // Function to handle link clicks and set the active link
  const handleLinkClick = (link) => {
    navigate(link);
    setActiveLink(link);
  };

  // useEffect to set the active link based on the current path
  useEffect(() => {
    const pathName = location.pathname;
    const pathArray = pathName.split("/");
    setActiveLink(pathArray[3]);
    if (outsideAnprOrOtd.test(pathName) || insideAnprOrOtd.test(pathName)) {
      setActiveLink(pathArray[3]);
    } else {
      setActiveLink("features");
    }
  }, [location.pathname, outsideAnprOrOtd, insideAnprOrOtd]);

  const renderFeatureButton = (featureName, linkDestination, isActive) => {
    return (
      <button
        to={linkDestination}
        className={`featurs-links ${isActive ? "active" : ""}`}
        onClick={() => handleLinkClick(linkDestination)}
      >
        <span className={`title ${isActive ? "active" : ""}`}>
          {featureName}
        </span>
      </button>
    );
  };
  const anyLinkIsActive =
    activeLink === "objectcount" ||
    activeLink === "anpr" ||
    activeLink === "smartbox";
  return (
    <div className="feature-section">
      <div className="featurs-con">
        {/* Conditionally render the Object Count link if the feature is available */}
        {hasObjectFeature &&
          renderFeatureButton(
            "Object Count",
            "objectcount",
            activeLink === "objectcount"
          )}

        {/* Conditionally render the ANPR Detection link if the feature is available */}
        {hasAnprFeature &&
          renderFeatureButton("ANPR Detection", "anpr", activeLink === "anpr")}

        {/* Conditionally render the SmartBox Detection link if the feature is available */}
        {hasSmartFeature &&
          renderFeatureButton(
            "SmartBox Detection",
            "smartbox",
            activeLink === "smartbox"
          )}
      </div>
      <div className="bg">
        {/* Render the nested routes */}
        {!anyLinkIsActive && (
          <>
            <h2 className="feature-info"> Features Instructions</h2>
            <div className="GuideCards">
              {hasObjectFeature && <PeapleGuide />}
              {hasAnprFeature && <AnprGuideCard />}
              {hasSmartFeature && <SmartGuide />}
            </div>
          </>
        )}
        {anyLinkIsActive && <Outlet />}
      </div>
    </div>
  );
};

export default Features;
