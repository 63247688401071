import React, { useEffect, useRef, useState } from "react";
import "../../Assets/Styles/cameraComponent.css";

import { useLocation } from "react-router-dom";

const CameraComponent = ({
  deviceName,
  startRTSPFeed,
  stopRTSPFeed,
  streamDataUrl,
  closeMultiStreams,
  currentRectRef,
  setRectangles,
  setCurrentRect,
  currentRect,
  rectangles,
  loading,
  videoLoaded
}) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const regex = /^\/dashboard\/features\/(anpr|smartbox)\/\w+/;

  const location = useLocation();
  const pythonApiUrl = process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL

  const params = new URLSearchParams(location.search);
  const deviceId = params.get("device_id");
  const [pathName, setPathName] = useState("");

  useEffect(() => {
    setPathName(location.pathname);

    return () => {
      closeMultiStreams();
    };
  }, []);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas not found");
      return;
    }

    const context = canvas.getContext("2d");
    if (!context) {
      console.error("2D context not found");
      return;
    }

    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Redraw all rectangles
    rectangles.forEach((rect) => {
      context.strokeStyle = "blue";
      context.lineWidth = 5;
      context.strokeRect(
        rect.startX,
        rect.startY,
        rect.endX - rect.startX,
        rect.endY - rect.startY
      );
    });

    // Draw the current rectangle
    if (currentRect) {
      context.strokeStyle = "blue";
      context.lineWidth = 5;
      context.strokeRect(
        currentRect.startX,
        currentRect.startY,
        currentRect.endX - currentRect.startX,
        currentRect.endY - currentRect.startY
      );
    }
  }, [rectangles, currentRect]);

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setCurrentRect({
      startX: e.clientX - rect.left,
      startY: e.clientY - rect.top,
      endX: e.clientX - rect.left,
      endY: e.clientY - rect.top,
    });
    setIsDrawing(true);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setCurrentRect((prevRect) => ({
      ...prevRect,
      endX: e.clientX - rect.left,
      endY: e.clientY - rect.top,
    }));
  };

  const handleMouseUp = () => {
    if (currentRect) {
      setRectangles([currentRect]);
      setCurrentRect(null);
    }
    setIsDrawing(false);
  };

  useEffect(() => {
    if (currentRect !== null) {
      currentRectRef.current = currentRect;
    }
  }, [currentRect]);

  return (
    <div className="configStream ">
      <h1>{deviceName}</h1>
      {regex.test(pathName) ? (
        <div className="canvas-container1">
          {loading || videoLoaded && <div className="loaders"></div>}
       <canvas
            className="canvas"
            id="video-canvas"
            width="800px"
            height="600px"
            style={{ zIndex: 1 }}
          ></canvas>
          <canvas
            id="video"
            className="canvas"
            width="800px"
            height="600px"
            style={{ zIndex: 2 }}
            ref={canvasRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          ></canvas>
        </div>
      ) : (
        <div className="canvas-container1">
         {loading || videoLoaded && 
         <div className="loaders">
          
         </div> }
         <canvas id="video-canvas"></canvas> 
        </div>
      )}
      <div style={{ paddingLeft: "1rem" }}>
        <button className="configStream__btn" onClick={startRTSPFeed} disabled={loading || videoLoaded}>
          Start Stream 
        </button>
        <button
          className="configStream__btn"
          onClick={() => stopRTSPFeed("stop")}
          disabled={loading || videoLoaded}
        >
          Stop Stream
        </button>
        {regex.test(pathName) ? (
          <button
            className="configStream__btn"
            onClick={() => {
              setCurrentRect(undefined);
              setRectangles([]);
              currentRectRef.current = undefined;
            }}
          >
            Clear Rectangle
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default CameraComponent;
