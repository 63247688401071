import React, { useContext, useEffect, useState } from 'react';
import '../../Assets/Styles/notification.css'
import { IoMdNotifications } from "react-icons/io";
import { Link } from 'react-router-dom';
import api from '../Utils/api';
import { UserContext } from '../../ContextApi/MyContext';

const NotificationIcon = () => {
  const { notificationCount } = useContext(UserContext);

  return (
    <Link  to ="notification" className="notification-icon">
      <span className="notification-count">{notificationCount}</span>
      <IoMdNotifications className='icon'/>
    </Link>
  );
};

export default NotificationIcon;