import scannerimg from "../../Assets/Images/phoneimg.png";
import cimg from "../../Assets/Images/car.png";
import printerimg from "../../Assets/Images/printer.png";
import cctvimg from "../../Assets/Images/cctv.png";
import bbimg from "../../Assets/Images/BoomBarrier.png";
import posimg from "../../Assets/Images/pos.png";
import rfidimg from "../../Assets/Images/rfid.png";
import conimg from "../../Assets/Images/controller.png";
import peopleimg from "../../Assets/Images/people.png";
import wavesensorimg from "../../Assets/Images/wavesensor.png";
import anprimg from "../../Assets/Images/anpr.png";
import iotimg from "../../Assets/Images/iot.png";
import alertimg from "../../Assets/Images/alert.png";
import targetimg from "../../Assets/Images/target.png";
import faceimg from "../../Assets/Images/face.png";

const featureData = [
  {
    imgSrc: scannerimg,
    altText: "QR Scanner",
    title: "QR SCANNER:-",
    text: "We offer AI-integrated QR scanners for parking systems. Our advanced scanners read QR codes on parking slips, instantly capturing details like parking duration, car number plate, and date. Leveraging AI, our system ensures accurate data processing, seamless entry and exit, real-time tracking, and efficient parking management.",
    isTextLeft: false,
    bannerClass: 'banner_pink',
  },
  {
    imgSrc: cimg,
    altText: "Loop Detector",
    title: "LOOP DETECTOR:-",
    text: "We offer cutting-edge loop detector services with AI for optimal accuracy and efficiency. Our devices use induction loops in the road surface to instantly identify vehicles via a magnetic field. With AI, we provide real-time traffic insights and optimize vehicle flow, making travel safer and more comfortable.",
    isTextLeft: true,
    bannerClass: 'banner_pink',
  },
  {
    imgSrc: printerimg,
    altText: "Printer",
    title: "PRINTER:-",
    text: "Our AI-powered printers transform parking systems by creating parking slips with a simple hand wave, capturing key details like car number, entry time, and date. This touchless system reduces contact, simplifies the process, and enhances convenience, making parking management easy and efficient.",
    isTextLeft: false,
    bannerClass: 'banner_pink',
  },
  {
    imgSrc: cctvimg,
    altText: "CCTV Camera",
    title: "CCTV CAMERA:-",
    text: "AI-integrated CCTV cameras use advanced technology to detect and track objects and people in real-time, enhancing security by identifying potential threats quickly. These cameras also assist in counting people, which is critical for effectively managing crowds and resources. By using AI, these systems offer a more intelligent and efficient approach to surveillance.",
    isTextLeft: true,
    bannerClass: 'banner_blue',
  },
  {
    imgSrc: bbimg,
    altText: "Boom Barrier",
    title: "BOOM BARRIER:-",
    text: "Our AI-integrated boom barriers revolutionize traffic management and access control by detecting and evaluating vehicles. These barriers allow seamless access based on predefined criteria like permitted vehicle identification or time intervals. Ideal for parking lots, gated communities, and industrial sites, they ensure efficient traffic flow and enhanced security.",
    isTextLeft: false,
    bannerClass: 'banner_blue',
  },
  {
    imgSrc: posimg,
    altText: "Point of Sale (POS)",
    title: "POINT OF SALES (POS):-",
    text: "We provide AI-integrated printers that revolutionize parking systems. With just a wave of your hand, our smart printer instantly generates parking slips, capturing essential details like vehicle number, entry time, date, etc. This touchless solution enhances convenience, speeds up the process, and minimizes physical contact, making parking management seamless and efficient.",
    isTextLeft: true,
    bannerClass: 'banner_blue',
  },
  {
    imgSrc: rfidimg,
    altText: "RFID/Tag Reader",
    title: "RFID/TAG READER/FASTAG READER:-",
    text: "We offer advanced RFID and Fastag reader solutions for access control and automated tolling. Our state-of-the-art readers swiftly scan tags for seamless vehicle identification and management, ensuring efficient toll collection and traffic monitoring. RFID technology enables swift item tracking and seamless inventory management using radio waves.",
    isTextLeft: false,
    bannerClass: 'banner_pink',
  },
  {
    imgSrc: conimg,
    altText: "Controllers",
    title: "CONTROLLERS:-",
    text: "Specializing in AI-integrated controllers, we act as intelligent mediators between machines, facilitating seamless communication and efficient command execution. Our advanced controllers analyze real-time data using AI to optimize interactions among connected devices. From industrial processes to smart infrastructure, we ensure precision, reliability, and enhanced operational efficiency.",
    isTextLeft: true,
    bannerClass: 'banner_pink',
  },
  {
    imgSrc: peopleimg,
    altText: "People Counting",
    title: "PEOPLE COUNTING:-",
    text: "We specialize in advanced people-counting systems that precisely measure and analyze pedestrian traffic. Our technology provides real-time data insights, enhancing efficiency, streamlining operations, and improving customer service. These solutions facilitate informed decision-making and resource management in public spaces and retail outlets.",
    isTextLeft: false,
    bannerClass: 'banner_pink',
  },
  {
    imgSrc: wavesensorimg,
    altText: "Waving Sensors",
    title: "WAVING SENSORS:-",
    text: "We provide cutting-edge waving sensor solutions for enhanced convenience and accessibility. Using state-of-the-art technology, our sensors detect hand gestures for touchless interaction with devices. Ideal for automatic door openers, interactive displays, and sanitary solutions, they elevate user experience with intuitive and hygienic control options.",
    isTextLeft: true,
    bannerClass: 'banner_blue',
  },
  {
    imgSrc: anprimg,
    altText: "ANPR",
    title: "ANPR:-",
    text: "Specializing in ANPR (Automatic Number Plate Recognition), we revolutionize road security and efficiency. Our advanced systems use AI to swiftly capture and analyze vehicle number plates in real-time. Whether for parking management, traffic control, or security surveillance, our ANPR technology ensures seamless operations and enhanced safety.",
    isTextLeft: false,
    bannerClass: 'banner_blue',
  },
  {
    imgSrc: iotimg,
    altText: "IoT Devices",
    title: "IOT DEVICES:-",
    text: "Specializing in IoT solutions, we empower organizations with cutting-edge connectivity and data intelligence. Our devices gather real-time sensor data, enabling smarter decision-making across sectors like environmental monitoring, supply chain optimization, and smart cities. Scalable and secure, our IoT solutions drive innovation and performance.",
    isTextLeft: true,
    bannerClass: 'banner_blue',
  },
  {
    imgSrc: alertimg,
    altText: "Movement Alerts",
    title: "MOVEMENT ALERTS:-",
    text: "Get real-time email alerts whenever your valuables are moved. Our state-of-the-art technology ensures proactive security, keeping you informed and empowered to take immediate action from anywhere. With dependable, cutting-edge alert solutions, we provide seamless protection and peace of mind for what matters most.",
    isTextLeft: false,
    bannerClass: 'banner_pink',
  },
  {
    imgSrc: targetimg,
    altText: "Targeted Object Detection",
    title: "TARGETED OBJECT DETECTION:-",
    text: "AI-driven CCTV cameras utilize real-time algorithms to precisely identify and track objects, including people, vehicles, and specific items of interest. Leveraging machine learning, these cameras automate surveillance, trigger alerts for unusual behavior, and provide in-depth analytics, significantly boosting security.",
    isTextLeft: true,
    bannerClass: 'banner_pink',
  },
  {
    imgSrc: faceimg,
    altText: "Face Recognition",
    title: "FACE RECOGNITION:-",
    text: "Specializing in cutting-edge face recognition solutions, we redefine security and personalize customer experiences. Our technology enables seamless access control, personalized greetings, and secure identity verification with a glance. From building security to frictionless payments and tailored retail experiences, we prioritize accuracy, privacy, and convenience.",
    isTextLeft: false,
    bannerClass: 'banner_blue',
  },
];

export default featureData;
