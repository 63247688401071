import { useState, useCallback } from 'react';
import api from '../Utils/api';

const useLastAlertData = (clientName) => {
  const [aData, setAlertData] = useState();
  const [error, setError] = useState(null);

  const getLastAlertData = useCallback(() => {
    console.log("Fetching alert data...");
      api.get(`api/devices/${clientName}/lastalertdata`)
      .then((response) => {
        if (response.status === 200) {
          const alertData = response.data;
          setAlertData(alertData);
        }
        if (response.status === 400) {
          console.log("Bad Request 🥱");
        }
        if (response.status === 500) {
          console.log("Internal Server Error");
        }
      })
      .catch((error) => {
        setError(error);
      });
  }, [clientName]);

  return { aData, error, getLastAlertData };
};

export default useLastAlertData;
