import React from 'react';
import { IoMdTime } from 'react-icons/io';
import "../../Assets/Styles/detectedViewCard.css"

const DetectedViewCard = ({ alertData }) => {

  const isEmpty = (obj) => {
    return !obj || Object.keys(obj).length === 0;
  };

  if (isEmpty(alertData)) {
    return (
      <div className="no-alerts">
        NO PROCESS ALERTS AVAILABLE
      </div>
    );
  }

  return (
    <div className="detected-view-card">
      <div className="notification-header">
        <img src={`data:image/png;base64,${alertData?.image}`} alt="detectedImage" className="detected-image" />
        <div className="notification-content">
          <div className="notification-body">
            <span className="notification-type">
              Device Id: {alertData?.device_id}
            </span>
            <br />
            <span className="notification-feature">
              <strong>Feature Name:</strong> {alertData?.featureName}
            </span>
            <br />
            <span className="configuration-status">
              {alertData?.message}
            </span>
            <br />
            <span className="notification-message">
              ProcessId: {alertData?.processId}
            </span>
          </div>
          <div className="notification-time">
            <div className="notification-start">
              <IoMdTime size={16} className="time-icon" /> 
              <strong>Start Time:</strong> {alertData?.startTime}
            </div>
            <div className="notification-start">
              <IoMdTime size={16} className="time-icon" /> 
              <strong>Detection Time:</strong> {alertData?.timestamp}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetectedViewCard;
