import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../ContextApi/MyContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import { MdExpandMore, MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
import { FaInfoCircle } from 'react-icons/fa';

const InPeopleCount = () => {
    const {matchedPeople, GetMatchedPeople,GetPeopleData,uniquePeople, theme}=useContext(UserContext);
    const [page, setPage] = useState(0);
    const [dialogPage, setDialogPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [DialogRowsPerPage, setDialogRowsPerPage] = useState(5);
    const [openAlertImage, setOpenAlertImage] = useState(false);
    const [alertImage, setAlertImage] = useState(null);
    const [openViewList, setOpenViewList] = useState(null);

    const modalClassName =
    theme === "dark"
      ? "smsAlert__container dark-theme"
      : "smsAlert__container light-theme";

      useEffect(() => {
        GetMatchedPeople(201); 
    }, []);


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleDialogChangePage = (event, newPage) => {
        setDialogPage(newPage);
      };

    const isEmpty = (obj) => {
        return !obj || Object.keys(obj).length === 0;
      };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleDialogChangeRowsPerPage = (event) => {
        setDialogRowsPerPage(parseInt(event.target.value, 10));
        setDialogPage(0);
      };

    const openPersonDetails = (unique_id) => {
        setOpenViewList(true);
        GetPeopleData(unique_id,201);
    }
    const handleViewImage = (image) => {
        setAlertImage(image);
        setOpenAlertImage(true);
      };
      const handleViewClose = () =>{
        setDialogPage(0);
        setOpenViewList(false);
      }
      const handleClose = () => {
        setOpenAlertImage(false);
        setAlertImage(null);
      };

      const MainTableHeading = () => {
        return (
          <TableHead>
            <TableRow className="sms__tabler-row">
              <TableCell className="sms__tblecell">Sr.no</TableCell>
              <TableCell className="sms__tblecell">Unique Id</TableCell>
              <TableCell className="sms__tblecell">Count</TableCell>
              <TableCell className="sms__tblecell">Action</TableCell>
            </TableRow>
          </TableHead>
        );
      }

      const SubTableHeading = () => {
        return (
          <TableHead>
            <TableRow className="sms__tabler-row">
              <TableCell className="sms__tblecell">Sr. No</TableCell>
              <TableCell className="sms__tblecell">Unique Id</TableCell>
              <TableCell className="sms__tblecell">Gate</TableCell>
              <TableCell className="sms__tblecell">Device Name</TableCell>
              <TableCell className="sms__tblecell">Date Time</TableCell>
              <TableCell className="sms__tblecell">View Image</TableCell>
            </TableRow>
          </TableHead>
        );
      }


      if (isEmpty(matchedPeople)) {
        return <div className={modalClassName}>
            <h2 style={{margin:"1rem 0"}}>In People Counts</h2>  
            <TableContainer component={Paper}>
              <Table>
                <MainTableHeading />
                <TableBody>
                  <TableRow >
                    <TableCell colSpan={4} sx={{textAlign: 'center'}}>Person not Detected</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
        </div>
      };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, matchedPeople.length - page * rowsPerPage);
    const emptyRows1 = DialogRowsPerPage - Math.min(DialogRowsPerPage, uniquePeople.length - dialogPage * DialogRowsPerPage);

  return (
    <div className={modalClassName}>
        <h2 style={{margin:"1rem 0"}}>In People Counts</h2>  
        <TableContainer component={Paper}>
        <Table>
          <MainTableHeading />
          <TableBody>
            {matchedPeople.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((list, index) => (
              <TableRow key={list.id}>
                <TableCell className="data-cell">{page * rowsPerPage + index + 1}</TableCell>
                <TableCell className="data-cell">{list.unique_id}</TableCell>
                <TableCell className="data-cell">{list.count}</TableCell>
                <TableCell className="data-cell">
                  <IconButton onClick={() => openPersonDetails(list.unique_id)} >
                    <FaInfoCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 20 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={matchedPeople.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />  


      {/* Open Person Details Dialog Box */}
      <Dialog open={openViewList} onClose={handleViewClose} maxWidth="md" fullWidth>
        <DialogTitle>View People List</DialogTitle>
        <DialogContent>
        <TableContainer component={Paper}>
        <Table>
          <SubTableHeading />
          <TableBody>
            {uniquePeople.slice(dialogPage * DialogRowsPerPage, dialogPage * DialogRowsPerPage + DialogRowsPerPage).map((list, index) => (
              <TableRow key={list.id}>
                <TableCell className="data-cell">{dialogPage * DialogRowsPerPage + index + 1}</TableCell>
                <TableCell className="data-cell">{list.unique_id}</TableCell>
                <TableCell className="data-cell">{list.gate_type}</TableCell>
                <TableCell className="data-cell">{list.device_name}</TableCell>
                <TableCell className="data-cell">{list.timestamp}</TableCell>
                <TableCell className="data-cell">
                  <IconButton onClick={() => handleViewImage(list.image)}>
                    {openAlertImage ? <MdOutlineVisibility/> : <MdOutlineVisibilityOff/>}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows1 > 0 && (
              <TableRow style={{ height: 53 * emptyRows1 }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        </TableContainer>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={uniquePeople.length}
        rowsPerPage={DialogRowsPerPage}
        page={dialogPage}
        onPageChange={handleDialogChangePage}
        onRowsPerPageChange={handleDialogChangeRowsPerPage}
      />  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAlertImage} onClose={handleClose} fullWidth sx={{width: 450, height: 480, justifySelf: 'center', alignSelf: 'center'}}>
        {/* <DialogTitle>View Image</DialogTitle> */}
        <DialogContent>
          {alertImage ? (
            <img src={`data:image/png;base64,${alertImage}`} alt="Alert" style={{ width: '300px', height: '300px' }} />
          ) : (
            <p>No Image Available</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default InPeopleCount;
