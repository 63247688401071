import React, { useEffect, useRef, useState } from "react";
import JSMpeg from "@cycjimmy/jsmpeg-player";
import { CiPlay1 } from "react-icons/ci";
import { CiPause1 } from "react-icons/ci";
import "../../Assets/Styles/multipleStream.css";
import { MdOutlineZoomInMap } from "react-icons/md";
import { MdOutlineZoomOutMap } from "react-icons/md";

const VideoStream = ({ rtspUrl, wsUrl }) => {
  const [player, setPlayer] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    if (!JSMpeg) {
      console.error("JSMpeg is not defined");
      return;
    }
    const canvas = document.getElementById(`canvas-${rtspUrl}`);
    if (canvas) {
      const newPlayer = new JSMpeg.Player(wsUrl, { canvas: canvas });
      setPlayer(newPlayer);
    }

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [wsUrl]);

  const handleFullScreenClick = () => {
    // Check if fullscreen is enabled
    if (!document.fullscreenElement) {
      // If not enabled, go fullscreen
      if (videoContainerRef.current.requestFullscreen) {
        videoContainerRef.current.requestFullscreen();
      } else if (videoContainerRef.current.mozRequestFullScreen) {
        /* Firefox */
        videoContainerRef.current.mozRequestFullScreen();
      } else if (videoContainerRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        videoContainerRef.current.webkitRequestFullscreen();
      } else if (videoContainerRef.current.msRequestFullscreen) {
        /* IE/Edge */
        videoContainerRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      // If already fullscreen, exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  const handlePlayPause = (action) => {
    if (player) {
      if (action === "play") {
        player.play();
        setIsPlaying(true);
        console.log("play");
      } else if (action === "pause") {
        player.pause();
        setIsPlaying(false);
        console.log("pause");
      }
    }
  };

  return (
    <div className="camera_design" ref={videoContainerRef}>
      <div className="video-container">
        <canvas
          className="video-canva"
          id={`canvas-${rtspUrl}`}
          width="640"
          height="360"
        ></canvas>
        <div className="controls">
          {!isPlaying && (
            <button
              className="live__btn"
              onClick={() => handlePlayPause("play")}
            >
              <CiPlay1 className="play__pause" />
            </button>
          )}
          {isPlaying && (
            <button
              className="live__btn"
              onClick={() => handlePlayPause("pause")}
            >
              <CiPause1 className="play__pause" />
            </button>
          )}
        </div>
        <button onClick={handleFullScreenClick} className="full__screen-btn">
          {isFullScreen ? (
            <MdOutlineZoomInMap size={35} />
          ) : (
            <MdOutlineZoomOutMap />
          )}
        </button>
      </div>
    </div>
  );
};

export default VideoStream;
