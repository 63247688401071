import React from 'react'
import { MdOutlineViewList } from "react-icons/md";
import { NavLink } from 'react-router-dom'

const ReportList = ({ list,activeLink,handleLinkClick }) => {
  return (
    <>
      {list.map((item) =>
        <NavLink
          key={item.id}
          to={item.path}
          className={`navLink-reports ${activeLink === "report" ? "active" : ""}`}
          onClick={() => handleLinkClick(item.path)}
        >
          {item.icon}
          {item.name}
        </NavLink>
      )}
    </>

  )
}

export default ReportList