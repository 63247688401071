import React, { useEffect, useState } from "react";
import NotificationCard from "../Components/Notification/NotificationCard";
import "../Assets/Styles/notification.css";
import api from "../Components/Utils/api";

const NotificationPage = () => {
  const [notificationData, setNotificationData] = useState([]);
  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientName = clientData ? clientData.clientName : null;
  const [latestData, setLatestData] =  useState()
  useEffect(() => {
   
    fetchPorocessData();
  }, []);

  const fetchPorocessData = async () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
      const responce = await api.get(
        `/api/devices/${clientName}/getconfigdata`
      );
      const processDta = responce.data.configdata[0];
      // const inCompleteProcess = processDta.filter(
      //   (item) => item.status === "starting" || item.status === "started"
      // );
      setNotificationData(processDta);

      const latest = processDta[0];

    setLatestData(latest);

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="notifiction__page">
      <h1 className="notification__title">Notifications</h1>
      <div className="notifiction__list">
        {notificationData?.map((notification, index) => (
          <div key={index} className="notification_item">
            <NotificationCard notification={notification} notificationData= {notificationData} fetchPorocessData={fetchPorocessData} latestData={latestData}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationPage;
