import { useContext, useEffect } from "react";

import { UserContext } from "../ContextApi/MyContext";
import { Card, CardContent, Paper, TableContainer, Typography } from "@mui/material";

const DashBoard1 = () => {
    const {todayscount, GetDeviceWiseCount, deviceWiseTodaysCount, GetTodayPeopleCount, theme}=useContext(UserContext);
    useEffect(() =>{
        setInterval(() => {
            GetDeviceWiseCount();
            GetTodayPeopleCount();
        }, 5000);
    }, []);
        
    console.log(deviceWiseTodaysCount);
    const modalClassName =
    theme === "dark"
      ? "smsAlert__container dark-theme"
      : "smsAlert__container light-theme";

    return (
        <div className={modalClassName}>
            <TableContainer component={Paper} sx={{display: "flex", justifyContent: "space-between", padding: 5}}>
                <Card sx={{ width: '45%', height: 130, borderWidth: 1, borderColor: "", borderStyle: 'solid'}}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                            In People
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                        {todayscount.in}
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ width: '45%', height: 130, borderWidth: 1, borderColor: "", borderStyle: 'solid'}}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                            Out People
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                        {todayscount.out}
                        </Typography>
                    </CardContent>
                </Card>
            </TableContainer>

            <TableContainer component={Paper} sx={{display: "flex", justifyContent: "space-between", padding: 5}}>
                    <Card sx={{ width: '30%', height: 130, borderWidth: 1, borderColor: "", borderStyle: 'solid'}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                                Camera In
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                                {deviceWiseTodaysCount.camin}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{ width: '30%', height: 130, borderWidth: 1, borderColor: "", borderStyle: 'solid'}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                                Camera out
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                                {deviceWiseTodaysCount.camout}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card sx={{ width: '30%', height: 130, borderWidth: 1, borderColor: "", borderStyle: 'solid'}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                                Camera In
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                                {deviceWiseTodaysCount.camout1}
                            </Typography>
                        </CardContent>
                    </Card>
                {/* <Card sx={{ width: '30%', height: 130, borderWidth: 1, borderColor: "", borderStyle: 'solid'}}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                            Out Camera1
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                            40 
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ width: '30%', height: 130, borderWidth: 1, borderColor: "", borderStyle: 'solid'}}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                        Out Camera2
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
                            80
                        </Typography>
                    </CardContent>
                </Card> */}
            </TableContainer>
        </div>
    );

}

export default DashBoard1;