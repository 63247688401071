// src/hooks/useAlert.js
import Swal from 'sweetalert2';
const token = localStorage.getItem('token');
const useFeaturesAlert = () => {
  const showFeaturesAlert = ({ title, text, icon, position = 'top'}) => {
    if (token){
    Swal.fire({
        title,
        text,
        icon,
        position,
        showConfirmButton: true,
        customClass: {
          popup: 'small-swal-popup',
          title: 'small-swal-title',
          content: 'small-swal-content',
          backdrop: 'swal2-backdrop-hide',
        },
        backdrop: 'none',
        focusConfirm: true,
        willOpen: (popup) => {
          popup.focus();
          setTimeout(() => {
            if (document.activeElement === popup) {
              popup.blur();
            }
          }, 0);
        },
      });
    }
  };

  return showFeaturesAlert;
};

export default useFeaturesAlert;
