// SkeletonScreen.js
import React from "react";
import "./groupskeleton.css";

const GroupNotificationSkeleton = () => {
  return (
    <div className="smsModal smsModal_skeleton">
      {/* Title */}
      <div className="skeleton-titles"></div>
      <div className="skeleton-Container">
        {/* Alert Message */}
        <div className="skeleton-text"></div>

        {/* Group Selection */}
        <div className="skeleton-group-selection">
          <div className="skeleton-squire-box"></div>
          <div className="skeleton-squire-box"></div>
          <div className="skeleton-squire-box"></div>
        </div>

        {/* User Alert Selection */}
        <div className="skeleton-user-alert-selection">
          <div className="skeleton-titles"></div>
        </div>

        {/* Submit Scheduler Button */}
      </div>
      <div className="skeleton-submit-button-con">
        <div className="skeleton-submit-button"></div>
      </div>
    </div>
  );
};

export default GroupNotificationSkeleton;
