import React from "react";
import "../../Assets/Styles/login.css";
import { Link } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { BsFillEyeSlashFill } from "react-icons/bs";

const AuthForm = ({ title, fields, onSubmit, buttonText, LoginSingupText, routeName, showPassword, onTogglePassword }) => {
  return (
    <div className="login-container">
      <div className="card-container">
        <div className="containers">
          <p className="heading">{title}</p>
          {fields.map((field) => (
            <div key={field.id} className="form__group">
              <label htmlFor={field.id} className="form__label">
                {field.label}
              </label>
              <input
                type={field.type}
                className="form__input"
                id={field.id}
                placeholder={field.placeholder}
                required={field.required}
                value={field.value}
                onChange={field.onChange}
              />
              <div className="labelErrorGroup">
              {field.error && <span className="error">{field.error}</span>}
              </div>
              
              {field.id === "password" && ( // Only show toggle button for the password field
                <button className="toggle-password-btn" onClick={onTogglePassword}>
                  {showPassword ? <IoEyeSharp/> : <BsFillEyeSlashFill/>} {/* Change button text based on showPassword state */}
                </button>
              )}
            </div>
          ))}
          <span className='questionText'>
            {LoginSingupText.text}? <Link className="LoginSingupText" to={routeName}>{LoginSingupText.message}</Link>
          </span>
          <div className="btn__group">
            <button type="button" className="buttton-btn" onClick={onSubmit}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
