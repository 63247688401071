import React from "react";
import "../../Assets/Styles/notfound.css";
import { Link } from "react-router-dom";
const PageNotFound = () => {
  const token =!! localStorage.getItem('token')

  return (
      <div className="page-not-found">
        <div className="row">
                <img src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif" alt="" />
                <h1 className="text-center ">404</h1>
                <h3 className="h2">Look like you're lost</h3>
                <p>the page you are looking for not available!</p>
               {token ? <Link to='/dashboard' className="link_404">
                  Go to Home
                </Link>:
                 <Link to='/' className="link_404">
                 Go to Home
               </Link>
               }
                
        </div>
      </div>
  );
};

export default PageNotFound;
