import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import JSMpeg from "@cycjimmy/jsmpeg-player";
import { UserContext } from "../ContextApi/MyContext";
import CameraComponent from "../Components/DeviceConfig/CameraComponent";
import GroupNotificationConfigComponent from "../Components/DeviceConfig/GroupNotificationConfigComponent";
import "../Assets/Styles/singlePage.css";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import InoutComponent from "../Components/DeviceConfig/InoutComponent";
import { decryptUrl } from "../Components/Utils/CryptoUtils";
import api from "../Components/Utils/api";
// import AudioPlayer from "react-h5-audio-player";
// import "react-h5-audio-player/lib/styles.css";
// import mp3 from '../pages/1.mp3'

const ConfigPage = () => {
  const { deviceData, clientData, SuccessAlert, ErrorAlert, GetDeviceData } =
    useContext(UserContext);
  const location = useLocation();
  const [changeLocation, setChangeLocation] = useState(location);
  const [selectedModels, setSelectedModels] = useState([]);
  const [isTimeSpecific, setIsTimeSpecific] = useState(false);
  const [feature, setFeature] = useState();
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({});
  const [videoLoaded, setVideoLoaded] = useState(false);
  const currentRectRef = useRef(null);
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const regex = /^\/dashboard\/features\/smartbox\/\w+/;

  const [deviceId, setDeviceId] = useState(() => {
    const storedDeviceId = localStorage.getItem("deviceId");
    return storedDeviceId || params.get("device_id") || "";
  });
  const [decryptedData, setDecryptedData] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const encryptedData = params.get("data");
    if (encryptedData) {
      const decodedEncryptedData = decodeURIComponent(encryptedData);
      const decryptedQueryString = decryptUrl(decodedEncryptedData);
      const decryptedParams = new URLSearchParams(decryptedQueryString);
      const device_id = decryptedParams.get("device_id");
      setDeviceName(decryptedParams.get("device_name"));
      setDeviceId(device_id);
      setDecryptedData({ device_id });
    }
  }, [location.search]);
  const [currentRect, setCurrentRect] = useState();
  const [rectangles, setRectangles] = useState([]);
  const [nonCofigUser, setNotConfigUser] = useState([]);
  const [serviceStatus, setServiceStatus] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserModalOpen, setUserModalOpen] = useState(true);
  const [activeUserGroupData, setActiveUserGroupData] = useState();
  const [activeUserGroupData2, setActiveUserGroupData2] = useState();
  const [deviceName, setDeviceName]= useState()
  const [modelList, setModelList] = useState();
  const [groupData, setGroupData] = useState([]);
  const [showGroups, setShowGroups] = useState();
  const [numberOfPeople, setNumberOfPeople] = useState();
  const [status, setStatus] = useState("");
  const [groupName, setGroupName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [index, setIndex] = useState();
  const [condition, setCondition] = useState("=");
  const [anprBoxCoordinates, setAnprBoxCoordinates] = useState({
    sendBoxCoordinates: false,
    checkVehicleType: false,
  });

  const [wsPorts, setWsPort] = useState("");
  const clientName = clientData ? clientData.clientName : null;
  const storageData = JSON.parse(localStorage.getItem("activeDeviceData"));
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const streamData = storageData
    ? storageData.find((device) => device.device_id === deviceId)
    : null;
  const [pathName, setPathName] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    "Detect person in-out",
    // "Detect person inside the box",
    // "Detect object movement",
  ];

  useEffect(() => {
    console.log(location.pathname);
    setPathName(location.pathname);
    GetDeviceData();
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await getActiveUsersInGroup();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [changeLocation, deviceId]);

  const handleUserCheckboxChange = (event, index, status) => {
    const isChecked = event.target.checked;
    setStatus(isChecked);
    setGroupName(status);
    setIndex(index);
    setActiveUserGroupData((prevData) => {
      const updatedActiveData = prevData?.map((user, ind) =>
        ind === index
          ? { ...user, [status]: { type: "Buffer", data: [isChecked ? 1 : 0] } }
          : user
      );
      setActiveUserGroupData2(updatedActiveData);
      console.log("fff", updatedActiveData);
      return updatedActiveData;
    });
    setGroupData((prevGroupData) => {
      const updatedGroupData = prevGroupData.map((user, idx) => {
        return idx === index ? { ...user, status: isChecked } : user;
      });
      return updatedGroupData;
    });
  };

  useEffect(() => {
    updateSelectedNotificationGroup();
  }, [activeUserGroupData2]);

  useEffect(() => {
    console.log("activeUserGroupData", activeUserGroupData);
  }, [activeUserGroupData]);

  const closeMultiStreams = async () => {
    try {
      const response = await api.post(`/api/stream/stopall`);
    } catch (error) {
      console.error("Error starting streams");
    }
  };

  useEffect(() => {
    const url = wsPorts?.wsUrl;
    let canvas = document.getElementById("video-canvas");

    if (url && canvas) {
      if (typeof JSMpeg !== "undefined") {
        const player = new JSMpeg.Player(url, {
          canvas: canvas,
          autoplay: true,
          loop: true,
          // onPlay: () => {

          // },
        });

        return () => {
          player.destroy();
        };
      } else {
        console.error("JSMpeg is undefined");
      }
    } else {
      console.error("URL or canvas is not defined");
    }
    // playWarningSound();
  }, [wsPorts]);

  useEffect(() => {
    if (selectedUser && activeUserGroupData) {
      const activeUser = activeUserGroupData.find(
        (user) => user.userId === selectedUser?.id
      );
      if (activeUser) {
        setServiceStatus({
          SMS: activeUser.sms_status.data[0] === 1,
          whatsApp: activeUser.whatsapp_status.data[0] === 1,
          Email: activeUser.email_status.data[0] === 1,
        });
      }
    } else {
      setServiceStatus({
        SMS: false,
        whatsApp: false,
        Email: false,
      });
    }
    fetchUserList();
  }, [selectedUser]);

  const startRTSPFeed = () => {
    startStream();
    setVideoLoaded(true);

    setTimeout(() => {
      setVideoLoaded(false);
    }, 3000);
  };

  const stopRTSPFeed = () => {
    closeMultiStreams();
    setVideoLoaded(true);

    setTimeout(() => {
      setVideoLoaded(false);
    }, 3000);
  };

  const istOffset = 5.5 * 60 * 60 * 1000;

  const configData = {
    device_id: deviceId,
    costomId: 0,
    userId: selectedUser?.id,
    startTime: new Date(startDate.getTime() + istOffset),
    endTime: new Date(endDate.getTime() + istOffset),
    totalTime: "Not Available",
    path: "Not Available",
    sms_status: serviceStatus.SMS,
    whatsapp_status: serviceStatus.whatsApp,
    email_status: serviceStatus.Email,
  };
  const configDataPost = (processId, featureName) => {
    const payload = {
      url: streamData?.url,
      device_id: deviceId,
      startTime: new Date(configData.startTime).toISOString(),
      endTime: new Date(configData.endTime).toISOString(),
      processId: processId,
      featureName: featureName,
      status: "starting",
    };
   
      api.post(
        `api/devices/${clientName}/configdata`,
        payload
      )
      .then((response) => {
        SuccessAlert("Data sent successfully");
        // sentWebhookData();
      })
      .catch((error) => {
      console.error("Error sending data:", error);

      if (error.response) {
        ErrorAlert(error.response.data.message || error.message);
      } else if (error.request) {
        ErrorAlert("No response received from server. Please try again later.");
      } else {
        ErrorAlert("An unexpected error occurred.", error.message);
      }
    });
  };
  const pythonApiUrl = process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL;
  const edgeApiUrl = `api/devices/${clientName}/sendprocessconfigdata`;
  const BASE_URLS = {
    multipleObject: `/papi/webhook/multipleobject`,
    multipleObjectWithTime: `/papi/webhook/multipleobjectwithtime`,
    peopleCount: `/papi/webhook`,
    anprBox: `/papi/webhook/anprbox`,
    anpr: `/papi/webhook/anpr`,
    inOutDetection: `/papi/webhook/in-out-detection`,
    inOutDetectionWithTime: `/papi/webhook/in-out-detectionwithtime`,
    objectMovement: `/papi/webhook/objectmovement`,
    momentWithTime: `/papi/webhook/momentwithtime`,
    smartBoxPerson: `/papi/webhook/smartboxperson`,
    checkVehicleType: `/papi/webhook/vehicletype`,
  };

  const sendRequest = async (url, payload, processId, featureName) => {
    try {
      const response = await api.post(url, payload);
      configDataPost(processId, featureName);
      setTimeout(() => {
        SuccessAlert(response.data.message);
      }, 3000);

      // swal('Success', 'Data sent successfully', 'success');
      return response.data;
    } catch (error) {
      console.error("Error sending data:", error);
  
      // Improved error handling
      if (error.response) {
        ErrorAlert(error.response.data.message || error.message);
      } else if (error.request) {
        ErrorAlert("No response received from server. Please try again later.");
      } else {
        ErrorAlert("An unexpected error occurred.", error.message);
      }
    }
  };

  const sentWebhookData = (featureName, isChecked) => {
    var rectangle = currentRectRef;
    const processId = Math.floor(100000 + Math.random() * 900000).toString();
    if (featureName == "objectcount") {
      if (
        ((numberOfPeople === undefined || numberOfPeople === "") &&
          (!Array.isArray(selectedModels) || selectedModels.length === 0)) ||
        (numberOfPeople !== undefined &&
          numberOfPeople !== "" &&
          Array.isArray(selectedModels) &&
          selectedModels.length > 0)
      ) {
        ErrorAlert("Please provide the number of people or select models");
      } else if (numberOfPeople === undefined) {
        try {
          if (isTimeSpecific) {
            const featureName = "multipleObject";
            const response = api.post(edgeApiUrl, {
              url: streamData?.url,
              condition: counts,
              object: selectedModels,
              device_id: deviceId,
              processId: processId,
              db_name: clientName,
              operation: "start",
              apiUrl:pythonApiUrl+BASE_URLS.multipleObjectWithTime,
              startTime: startDate,
              endTime: endDate,
              featureName: featureName,
            });
            configDataPost(processId, featureName);
            setData(response.data);
          } else {
            const featureName = "multipleObject";
            const response = api.post(edgeApiUrl, {
              url: streamData?.url,
              condition: counts,
              object: selectedModels,
              device_id: deviceId,
              processId: processId,
              db_name: clientName,
              operation: "start",
              apiUrl: pythonApiUrl+BASE_URLS.multipleObject,
              featureName: featureName,
            });
            configDataPost(processId, featureName);
            setData(response.data);
          }
        } catch (error) {
          console.error("Error in multipleobject request:", error);
        }
      } else if (
        !Array.isArray(selectedModels) ||
        selectedModels.length === 0
      ) {
        const featureName = "multipleObject";
        const payload = {
          url: streamData?.url,
          device_id: deviceId,
          userId: selectedUser?.id,
          startTime: configData.startTime,
          endTime: configData.endTime,
          processId: processId,
          dbName: clientName,
          featureName:featureName,
          count_number: `${condition}${numberOfPeople}`,
          operation: "start",
          apiUrl: `${pythonApiUrl}${BASE_URLS.peopleCount}`
        };
        sendRequest(edgeApiUrl, payload, processId, featureName);
      }
    } else if (featureName == "anpr") {
      if (anprBoxCoordinates.sendBoxCoordinates) {
        if (rectangle?.current?.startX !== undefined) {
          const boundingBox = [
            rectangle?.current?.startX,
            rectangle?.current?.startY,
            rectangle?.current?.endX,
            rectangle?.current?.endY,
          ];
          const featureName = "anprbox";
          const payload = {
            url: streamData.url,
            bounding_box: boundingBox,
            db_name: clientName,
            processId: processId,
            operation: "start",
            device_id: deviceId,
            featureName: featureName,
            startTime: new Date(configData.startTime).toISOString(),
            endTime: new Date(configData.endTime).toISOString(),
            apiUrl: `${pythonApiUrl}${BASE_URLS.anprBox}`,
          };
          sendRequest(edgeApiUrl, payload, processId, featureName);
        } else {
          ErrorAlert(
            "Please draw box for detecting number plate inside the box"
          );
        }
      } else if (anprBoxCoordinates.checkVehicleType) {
        // New condition for checkVehicleType
        const featureName = "checkVehicleType";
        const payload = {
          url: streamData.url,
          device_id: deviceId,
          db_name: clientName,
          operation: "start",
          // startTime: new Date(configData.startTime).toISOString(),
          // endTime: new Date(configData.endTime).toISOString(),
          apiUrl: `${pythonApiUrl}${BASE_URLS.anprBox}`,
          processId: processId,
          featureName: featureName,
        };
        sendRequest(edgeApiUrl, payload, processId, featureName); // New API request for checkVehicleType
      } else {
        const featureName = "anpr";
        const payload = {
          url: streamData.url,
          device_id: deviceId,
          db_name: clientName,
          operation: "start",
          startTime: new Date(configData.startTime).toISOString(),
          endTime: new Date(configData.endTime).toISOString(),
          processId: processId,
          featureName: featureName,
          // apiUrl: BASE_URLS.anpr,
          apiUrl: pythonApiUrl+BASE_URLS.anpr,
        };

        sendRequest(edgeApiUrl, payload, processId, featureName);
      }
    } else if (featureName === "smartbox") {
      if (isChecked && selectedValue == "Detect person in-out") {
        const featureName = "inOutWithTime";

        if (
          rectangles[1]?.startX !== undefined &&
          rectangles[0]?.startX !== undefined
        ) {
          const payload = {
            url: streamData?.url,
            apiUrl: pythonApiUrl+BASE_URLS.inOutDetectionWithTime,
            db_name: clientName,
            device_id: deviceId,
            processId: processId,
            ay1: rectangles[0].startX,
            by1: rectangles[0].startY,
            cy1: rectangles[0].endX,
            dy1: rectangles[0].endY,
            ay2: rectangles[1].startX,
            by2: rectangles[1].startY,
            cy2: rectangles[1].endX,
            dy2: rectangles[1].endY,
            startTime: new Date(configData.startTime).toISOString(),
            endTime: new Date(configData.endTime).toISOString(),
            operation: "start",
            featureName: featureName,
          };
          sendRequest(edgeApiUrl, payload, processId, featureName);
        } else {
          ErrorAlert("Please draw two rectangles for entry and exit");
        }
      } else if (!isChecked && selectedValue == "Detect person in-out") {
        const featureName = "inOut";
        if (
          rectangles[0]?.startX !== undefined
        ) {
          const payload = {
            url: streamData?.url,
            apiUrl: pythonApiUrl+BASE_URLS.inOutDetection,
            db_name: clientName,
            device_id: deviceId,
            processId: processId,
            ay1: rectangles[0].startX,
            by1: rectangles[0].startY,
            cy1: rectangles[0].endX,
            dy1: rectangles[0].endY,
            operation: "start",
            featureName: featureName,
          };
          sendRequest(edgeApiUrl, payload, processId, featureName);
        } else {
          ErrorAlert("Please draw two box for entry and exit");
        }
      } else if (!isChecked && selectedValue === "Detect object movement") {
        if (rectangles[0]?.startX !== undefined) {
          const featureName = "objectMovement";

          const payload = {
            url: streamData?.url,
            apiUrl: pythonApiUrl+BASE_URLS.objectMovement,
            ay1: rectangles[0].startX,
            by1: rectangles[0].startY,
            cy1: rectangles[0].endX,
            dy1: rectangles[0].endY,
            device_id: deviceId,
            processId: processId,
            db_name: clientName,
            operation: "start",
            featureName: featureName,
          };

          sendRequest(edgeApiUrl, payload, processId, featureName);
        } else {
          ErrorAlert(
            "Please draw box to detect object movement inside the box"
          );
        }
      } else if (isChecked && selectedValue === "Detect object movement") {
        const featureName = "objectMovementwithTime";
        if (rectangles[0]?.startX !== undefined) {
          {
            const payload = {
              url: streamData.url,
              apiUrl: pythonApiUrl+BASE_URLS.momentWithTime,
              ay1: rectangles[0].startX,
              by1: rectangles[0].startY,
              cy1: rectangles[0].endX,
              dy1: rectangles[0].endY,
              startTime: new Date(configData.startTime).toISOString(),
              endTime: new Date(configData.endTime).toISOString(),
              device_id: deviceId,
              processId: processId,
              db_name: clientName,
              featureName: featureName,
              operation: "start",
            };
            sendRequest(edgeApiUrl, payload, processId, featureName);
          }
        } else {
          ErrorAlert("Please draw box to detect object movement the box");
        }
      } else if (isChecked && selectedValue === "Detect person inside the box")
        if (rectangles[0]?.startX !== undefined) {
          const featureName = "detectPersonInsideTheBox";

          console.log("!isChecked && movementChecked", processId);

          const payload = {
            url: streamData.url,
            apiUrl: BASE_URLS.smartBoxPerson,
            ay1: rectangles[0].startX,
            by1: rectangles[0].startY,
            cy1: rectangles[0].endX,
            dy1: rectangles[0].endY,
            startTime: new Date(configData.startTime).toISOString(),
            endTime: new Date(configData.endTime).toISOString(),
            processId: processId,
            device_id: deviceId,
            db_name: clientName,
            operation: "start",
            featureName: featureName,
          };
          console.log("payload", payload);
          sendRequest(edgeApiUrl, payload, processId, featureName);
        } else {
          ErrorAlert("Please draw box to detect person movement the box");
        }
      else if (!isChecked && selectedValue === "Detect person inside the box")
        if (rectangles[0]?.startX !== undefined) {
          const featureName = "detectPersonInsideTheBox";

          const payload = {
            url: streamData.url,
            apiUrl: pythonApiUrl+BASE_URLS.smartBoxPerson,
            ay1: rectangles[0].startX,
            by1: rectangles[0].startY,
            cy1: rectangles[0].endX,
            dy1: rectangles[0].endY,
            processId: processId,
            device_id: deviceId,
            db_name: clientName,
            operation: "start",
            featureName: featureName,
          };
          sendRequest(edgeApiUrl, payload, processId, featureName);
        } else {
          ErrorAlert("Please draw box to detect person movement the box");
        }
    }
  };

  const startStream = () => {
    setLoading(true);
    api
      .post(`/api/stream/start`, {
        rtspUrl: streamData?.url,
        wsPort: `${streamData?.wsport}`,
      })
      .then((response) => {
        setWsPort(response.data);
        console.log("response.data")
        console.log(response.data)
        setLoading(false);
      })
      .catch((error) => {
        // ErrorAlert(error.response.data);
        console.log(error.response)
        // Check if the error response exists and has a status code
        if (error.response) {
          if (error.response.status === 500) {
            ErrorAlert("Internal Server Error. Please try again later.");
            setVideoLoaded(false);
          } else {
            ErrorAlert(`Error: ${error.response.data.error}`);
            setVideoLoaded(false);
          }
        } else {
          ErrorAlert("An unknown error occurred. Please try again.");
        }

        setLoading(false);
      });
  };

  const handleStartTimeChange = (time) => {
    setStartDate(time);
  };

  const handleEndTimeChange = (time) => {
    setEndDate(time);
  };

  const fetchUserList = () => {
    const apiUrl = `/api/devices/${clientName}/user`;

    api
      .get(apiUrl)
      .then((response) => {
        const activeuser = response.data.userList[0];
        const activeusers = activeuser.filter(
          (user) => user.status?.data?.[0] !== 0
        );
        setUserList(activeusers);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const GetConfigData = async () => {
    try {
      api.get(`api/devices/${clientName}/getconfigdata`).then((response) => {
        setNotConfigUser(response.data.configdata[0]);
      });
    } catch (error) {
      console.error("Error fetching device status:", error);
    }
  };

  const getActiveUsersInGroup = () => {
    api
      .get(`api/devices/${clientName}/getactivestatus/${deviceId}`)
      .then((response) => {
        setActiveUserGroupData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching active users:", error);
      });
  };

  const updateSelectedNotificationGroup = () => {
    api
      .patch(`api/devices/${clientName}/update_active_users_in_group`, {
        userId: userList[index]?.id,
        status: status === true ? 1 : 0,
        groupName: groupName,
        deviceId: deviceId,
      })
      .then((response) => {
        console.log(response);
        GetConfigData();
        getActiveUsersInGroup();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  const handleNumberOfPeopleForAlert = (e) => {
    const numberOfPeople = e.target.value;
    setNumberOfPeople(numberOfPeople);
  };

  const handleAlertConditionChange = (e) => {
    const condition = e.target.value;
    if (condition === "atleast") setCondition(">=");
    else if (condition === "greater_than") setCondition(">");
    else if (condition === "smaller_than") setCondition("<");
    else if (condition === "atmost") setCondition("<=");
    else if (condition === "equals") setCondition("=");
  };

  return (
    <div className="config-page">
      <div className="single-page">
        {!regex.test(pathName) && (
          <CameraComponent
            streamDataUrl={streamData?.url}
            deviceName={deviceName}
            startRTSPFeed={startRTSPFeed}
            stopRTSPFeed={stopRTSPFeed}
            closeMultiStreams={closeMultiStreams}
            currentRectRef={currentRectRef}
            setRectangles={setRectangles}
            setCurrentRect={setCurrentRect}
            rectangles={rectangles}
            currentRect={currentRect}
            loading={loading}
            videoLoaded={videoLoaded}
          />
        )}

        {isUserModalOpen && regex.test(pathName) && (
          <InoutComponent
            rectangles={rectangles}
            setRectangles={setRectangles}
            streamDataUrl={streamData?.url}
            startRTSPFeed={startRTSPFeed}
            stopRTSPFeed={stopRTSPFeed}
            closeMultiStreams={closeMultiStreams}
            currentRectRef={currentRectRef}
            setCurrentRect={setCurrentRect}
            currentRect={currentRect}
            deviceName={deviceName}
          />
        )}

        {!showGroups && (
          <GroupNotificationConfigComponent
            counts={counts}
            setCounts={setCounts}
            modelList={modelList}
            options={options}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
            setModelList={setModelList}
            selectedModels={selectedModels}
            setSelectedModels={setSelectedModels}
            showGroups={showGroups}
            sentWebhookData={sentWebhookData}
            setShowGroups={setShowGroups}
            deviceData={deviceData}
            clientData={clientData}
            groupData={groupData}
            startTime={startDate}
            endTime={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            handleUserCheckboxChange={handleUserCheckboxChange}
            updateSelectedNotificationGroup={updateSelectedNotificationGroup}
            userList={userList}
            activeUserGroupData={activeUserGroupData}
            nonCofigUser={nonCofigUser}
            handleNumberOfPeopleForAlert={handleNumberOfPeopleForAlert}
            handleAlertConditionChange={handleAlertConditionChange}
            handleStartTimeChange={handleStartTimeChange}
            handleEndTimeChange={handleEndTimeChange}
            deviceId={deviceId}
            startDate={startDate}
            endDate={endDate}
            setFeature={setFeature}
            setAnprBoxCoordinates={setAnprBoxCoordinates}
            anprBoxCoordinates={anprBoxCoordinates}
            isLoading={isLoading}
            numberOfPeople={numberOfPeople}
            setNumberOfPeople={setNumberOfPeople}
            setCondition={setCondition}
            condition={condition}
            configDataPost={configDataPost}
            isTimeSpecific={isTimeSpecific}
            setIsTimeSpecific={setIsTimeSpecific}
          />
        )}
      </div>
    </div>
  );
};

export default ConfigPage;
