import React, { useEffect } from "react";
import "../../Assets/Styles/Home.css";
import Slider from "../../Components/Homepage/Slider.js";
import eyeimg from "../../Assets/Images/eye.png";
import bulbimg from "../../Assets/Images/bulb.png";
import stairsimg from "../../Assets/Images/stairs.png";

import Footer from "./Footer.jsx";

const useRevealOnScroll = (elements) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
            observer.unobserve(entry.target); // Stop observing once the element is revealed
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    elements.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [elements]);
};

const Home = () => {
  const banner1ContentRef = React.useRef(null);
  const banner1BottomRef = React.useRef(null);
  const banner2ContentRef = React.useRef(null);
  const banner2ImgRef = React.useRef(null);

  useRevealOnScroll([
    banner1ContentRef.current,
    banner1BottomRef.current,
    banner2ContentRef.current,
    banner2ImgRef.current,
  ]);

  return (
    <div className="home">
      <div className="slide-container">
        <Slider />
      </div>
      <div className="home__banner1">
        <div className="home__banner1-content" ref={banner1ContentRef}>
          <ul className="home__banner1-content-ul">
          <h1>
            Accelerate business transformation with AI technology solutions
          </h1>
            <li>
              Discover how to use AI to solve corporate problems, streamline
              procedures, and improve client care.
            </li>
            <li>
              From small businesses to huge organizations, our scalable and
              flexible AI solutions expand with your organization.
            </li>
            <li>
              Our AI solutions keep you ahead in the modern digital landscape by
              enhancing creativity and efficiency.
            </li>
            <li>
              Boost operations, generate insights, and provide outstanding
              customer experiences for your company with AI.
            </li>
           
            <li>
              Artificial intelligence (AI) can change your business for the
              better by increasing productivity by up to 40% and lowering
              operating costs by 20%.
            </li>
            <li>
              With Buy AI solutions, you can experience the business of the
              future. Our AI solutions are not only intelligent, but they also
              give you a competitive advantage by improving customer service and
              automating jobs.
            </li>
            <li>
              <strong>Are you aware of this?</strong>
              <br />
              Our scalable solutions expand with you, even if you're a
              fast-moving startup or an international corporation, and they
              effortlessly adapt to the fast-paced digital world of today.
            </li>
          </ul>
        </div>
        <div className="home__banner1-bottom" ref={banner1BottomRef}>
          <h1>
            "Seeing the World Through AI's Eyes: Powering Tomorrow's Visual
            Intelligence"
          </h1>
          <img src={eyeimg} alt="img" className="eyeimg" />
        </div>
      </div>
      <div className="home__banner2">
        <div className="vision">
          <div className="home__banner2-img" ref={banner2ImgRef}>
            <img src={bulbimg} alt="img" className="bulbimg" />
            <h2>Our Vision</h2>
          </div>
          <div className="home__banner2-content" ref={banner2ContentRef}>
            <p>
              Here at BuyAIsolution, we see a future in which artificial
              intelligence turns routine conversations into amazing adventures.
              Imagine a day in the future when our sophisticated AI systems will
              be able to accurately identify and comprehend both people and
              objects, promoting innovation in every industry and assuring
              public safety. Our goal is to spearhead this change by enabling
              everyone to benefit from and be impacted by intelligent solutions.
            </p>
          </div>
        </div>
        <div className="mission">
          <div className="mission-content">
            <p>
              At BuyAIsolution, we provide clever, useful AI solutions to
              improve both personal and professional lives. With a focus on
              sophisticated item and person detection, we increase security,
              foster creativity, and increase productivity. People can be
              reliably tracked by our AI in public spaces, businesses, and
              homes. Our AI-driven detection streamlines manufacturing,
              transportation, and retail processes by decreasing errors and
              raising efficiency. By continuously pushing the limits of AI, we
              find fresh approaches to pressing issues and incorporate
              intelligent robots into daily life. We at BuyAIsolution aren't
              simply envisioning the AI of the future; we're building it.
            </p>
          </div>
          <div className="home__banner2-img">
            <h2>Our Mission</h2>
            <img src={stairsimg} alt="img" className="Missionimg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
