import React, { useContext, useEffect, useState } from "react";
import Card from "../../Components/Utils/Card";
import "../../Assets/Styles/live.css";
import { UserContext } from "../../ContextApi/MyContext";

const PeapleCount = () => {
  const { peopleDevices, GetDeviceData } = useContext(UserContext);
  useEffect(() => {
    GetDeviceData();
  }, []);

  return (
    <div className="peaple-count">
      <div className="people-title">
        <h3>People Count Camera List</h3>
      </div>
      <div className={`cards`}>
        {peopleDevices.map((item, i) => (
          <Card key={item.device_id} id={item.device_id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default PeapleCount;