import Modal from "react-modal";
import { Outlet, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import "../../Assets/Styles/AnnotateLabel.css";
import { UserContext } from "../../ContextApi/MyContext";
import Create from "./Create";
import InstructionsModal from "../../Components/GuideComponents/InstructionsModal";
import { v4 as uuidv4 } from "uuid";

function AnnotateLabel() {
  const [projectName, setProjectName] = useState("");
  const [validationError, setValidationError] = useState("");
  const [data, setData] = useState();
  const { deviceData, clientData, GetDeviceData } = useContext(UserContext);
  const [modelIsOpen, setIsOpen] = useState();
  const navigate = useNavigate();
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(true);
  const [projectId, setProjectId] = useState();
  const clientName = clientData ? clientData.clientName : null;
  const clientUserName = clientData ? clientData.username : null;

  useEffect(() => {
    GetDeviceData();
  }, []);

  const validateProjectName = () => {
    if (!projectName.trim()) {
      setValidationError("Project name cannot be empty.");
      return false;
    } else if (projectName.length < 3) {
      setValidationError("Project name must be at least 3 characters long.");
      return false;
    }
    setValidationError("");
    return true;
  };

  const openModel = () => {
    setIsOpen(true);
  };

  const closeModel = () => {
    setIsOpen(false);
  };

  const modelTrainApioptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      project_name: projectName,
      username: clientUserName,
      project_id: projectId,
    }),
  };

  const handleProjectName = async (projectName, projectId, create) => {
    if (create == "create") {
      navigate("../create", {
        state: { projectName: projectName, projectId: projectId },
      });
    } else {
      navigate("../build", {
        state: { projectName: projectName, projectId: projectId },
      });
    }
  };

  const handleInputChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleCreate = (create) => {
    if (validateProjectName()) {
      function shortenUuid() {
        const myUuid = uuidv4();
        const projectId = myUuid.substring(0, 8);
        console.log(`Building project "${projectId}"...`);

        handleProjectName(projectName, projectId, create);
      }
      shortenUuid();
    }
  };

  const handleBuild = (build) => {
    if (validateProjectName()) {
      function shortenUuid() {
        const myUuid = uuidv4();
        const projectId = myUuid.substring(0, 8);
        handleProjectName(projectName, projectId, build);
      }
      shortenUuid();
      modelTrainApi();
    }
  };

  const [isHovered, setIsHovered] = useState({ build: false, create: false });

  const modelTrainApi = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL}/create_project/`,
        modelTrainApioptions
      );
      console.log("modelTrainApioptions", modelTrainApioptions);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleMouseEnter = (type) => {
    setIsHovered((prevState) => ({ ...prevState, [type]: true }));
  };

  const handleMouseLeave = (type) => {
    setIsHovered((prevState) => ({ ...prevState, [type]: false }));
  };

  // useEffect(() => {
  //   if (projectName) {
  //     shortenUuid();
  //   }
  // }, [projectName]);
  return (
    <div className={isInstructionsOpen ? "container1" : "container2"}>
      {isInstructionsOpen && (
        <InstructionsModal
          setIsInstructionsOpen={setIsInstructionsOpen}
          isInstructionsOpen={isInstructionsOpen}
        />
      )}
      <div className="anotationCon">
        <div className="anotation__group">
          <div className="anotation__contaner">
            <label htmlFor="projectName">Project Name: </label>
            <input
              type="text"
              id="projectName"
              placeholder={
                validationError
                  ? "This filed required"
                  : "Enter Your Project Name"
              }
              className={
                validationError ? "notationInputError" : "notationInputText"
              }
              value={projectName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="anotation__contaner">
            <button
              className="button1"
              title="Create with existing data"
              onMouseEnter={() => handleMouseEnter("create")}
              onMouseLeave={() => handleMouseLeave("create")}
              onClick={() => handleCreate("create")}
            >
              Create
            </button>
            <button
              className="button1"
              title="Build your own data"
              onMouseEnter={() => handleMouseEnter("build")}
              onMouseLeave={() => handleMouseLeave("build")}
              onClick={() => handleBuild("build")}
            >
              Build
            </button>
          </div>
        </div>
      </div>
      <div className="bg">
        <Outlet />
      </div>
      <Modal
        isOpen={modelIsOpen}
        contentLabel="Example Modal"
        className="Anotation__modal"
        overlayClassName="anotation__overlay"
        onRequestClose={closeModel}
        ariaHideApp={false}
      >
        <Create
          closeModel={closeModel}
          projectName={projectName}
          projectId={projectId}
        />
      </Modal>
    </div>
  );
}

export default AnnotateLabel;
