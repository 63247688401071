import React, { useState, useEffect, useContext } from "react";
import { PieChart, Pie, Tooltip, Legend } from "recharts";
import { UserContext } from "../../ContextApi/MyContext";

const DeviceActiveChart = () => {
  const { deviceData, GetDeviceData } = useContext(UserContext);
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  useEffect(() => {
    GetDeviceData();
  }, []);

  // Compute counts of active and inactive devices
  const activeDevices = deviceData.filter(
    (device) => device.status?.data?.[0] !== 0
  );
  const inactiveDevices = deviceData.filter(
    (device) => device.status?.data?.[0] === 0
  );

  // Prepare data for donut chart
  const chartData = [
    { name: "Active Devices", value: activeDevices.length },
    { name: "Inactive Devices", value: inactiveDevices.length },
  ];

  return (
    <div className="device-report">
      <h1>Device Management System</h1>
      <PieChart width={400} height={400}>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          label
        />
        <Tooltip />
        <Legend />
      </PieChart>
    </div>
  );
};

export default DeviceActiveChart;
