import { createContext, useState } from 'react';
import Swal from 'sweetalert2';

export const PublicContext = createContext();

const PublicProvider = ({ children }) => {
  const SuccessAlert = (success) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: success,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const ErrorAlert = (error) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  };

  const DeleteAlert = () => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        resolve(result);
      });
    });
  };

  const publicData = ({
    SuccessAlert,
    ErrorAlert,
    DeleteAlert
  })
  return (
    <PublicContext.Provider value={publicData}>
      {children}
    </PublicContext.Provider>
  );
};

export default PublicProvider;
