import React, { useContext, useState } from "react";
import AuthForm from "../../Components/Auth/AuthForm";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";


const Login = () => {
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false); // New state for toggling password visibility
  const SuccessAlert = (success) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: success,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const ErrorAlert = (error) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  };
  const validateForm = () => {
    const errors = {};
    if (!username.trim()) {
      errors.username = "Username is required";
     
    }
    if (!password.trim()) {
      errors.password = "Password is required";
      
    }
    return errors;
  };
  // const loginPthonToken = async () => {
  //   const jwtToken = localStorage.getItem('token');
  
  //   try {
  //     const response = await axios.get(
  //       "http://192.168.1.5:8083/verify", 
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${jwtToken}`,
  //         },
  //       }
  //     );
  
  //     if (response.status === 200) {  // Check for 200 OK status
  //       SuccessAlert("Server verified successfully", response.status);
  //       console.log("Server verified successfully");
  //     } else {
  //       ErrorAlert(response.data.error || "Failed to verify server");
  //     }
  //   } catch (error) {
  //     // Handle the error response
  //     ErrorAlert(error.response?.data?.error || "An error occurred during server verification");
  //     console.error("Error during server verification:", error);
  //   }
  // };
  


  const handleLogin = async() => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/login`, {
          username,
          password,
        })
        .then(async response => {
          const data = response.data;
          if (data.success) {
            const { token, role, client_id } = data.client;
            // Store the token in localStorage
            localStorage.setItem("token", token);
            localStorage.setItem("role", role);
            localStorage.setItem("client_id", client_id);
  
            // Additional login actions
            // loginModelTrainApi();
            SuccessAlert(data.message);
            navigate("../dashboard");
            localStorage.setItem("client", JSON.stringify(data.client));
            // await loginPthonToken()
          } else {
            // Handle application-level errors
            ErrorAlert(data.message);
          }
        })
        .catch(error => {
          if (error.response) {
            // Server responded with a status other than 2xx
            ErrorAlert(`Server error: ${error.response.status}`);
          } else if (error.request) {
            // Request was made but no response received
            ErrorAlert("Connection refused! Please check if the server is running.");
          } else {
            // Something else happened in setting up the request
            ErrorAlert(`Internal server error: ${error.message}`);
          }
          console.error("Error during login:", error);
        });
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <>
      <AuthForm
        title="Login"
        fields={[
          {
            id: "username",
            type: "text",
            placeholder: "User Name",
            required: true,
            value: username,
            onChange: (e) => setUserName(e.target.value),
            label: "User Name",
            error: errors.username,
          },
          {
            id: "password",
            type: showPassword ? "text" : "password",
            placeholder: "Password",
            required: true,
            value: password,
            onChange: (e) => setPassword(e.target.value),
            label: "Password",
            error: errors.password,
          },
        ]}
        showPassword={showPassword} // Pass the showPassword state to the AuthForm component
        onTogglePassword={() => setShowPassword(!showPassword)}
        onSubmit={handleLogin}
        buttonText="Login"
        LoginSingupText={{
          text: "Don't have an account",
          message: "Create an account",
        }}
        routeName="/signup"
      />
    </>
  );
};

export default Login;
