import React, { useContext } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import { UserContext } from "../../ContextApi/MyContext";
import { IoMdClose } from "react-icons/io";
import { IoMdTime } from "react-icons/io";
import { IoReload } from "react-icons/io5";
import api from "../Utils/api";

const NotificationCard = ({
  notification,
  notificationData,
  fetchPorocessData,
  latestData,
}) => {
  const clientData = JSON.parse(localStorage.getItem("client"));
  const clientName = clientData ? clientData.clientName : null;
  const { DeleteAlert, ErrorAlert, SuccessAlert } = useContext(UserContext);

  const pythonApiUrl = process.env.REACT_APP_MODEL_TRAIN_API_BASE_URL;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const BASE_URLS = {
    multipleObject: `/papi/webhook/multiple_object`,
    anprBox: `/papi/webhook/anprbox`,
    anpr: `/papi/webhook/anpr`,
    inOutDetection: `/papi/webhook/in-out-detection`,
    inOutDetectionWithTime: `/papi/webhook/in-out-detectionwithtime`,
    objectMovement: `/papi/webhook/objectmovement`,
    momentWithTime: `/papi/webhook/momentwithtime`,
    smartBoxPerson: `/papi/webhook/smartboxperson`,
    customTrainModel: `/papi/webhook/detect`,
    checkVehicleType: `/papi/webhook/vehicletype`,
    stopProcessInEdge: `/api/devices/${clientName}/stopprocess`,
  };

  const sendRequest = async (
    processId,
    featureName,
    notification,
    clientName
  ) => {
    try {
      console.log("in sendRequest ", processId, featureName, notification);

      const payload = createPayload(notification, clientName, null);

      // Simulate user confirmation with a flag or state management
      const confirmed = true; // This should ideally come from user input or application state

      if (!confirmed) {
        return;
      }

      await executeFeatureRequest(featureName);
      fetchPorocessData();
    } catch (error) {
      let errorMessage = "Unexpected error occurred";
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = `${error.response.status}: ${error.response.data.message}`;
      } else {
        // Something happened in setting up the request that triggered an Error
        ErrorAlert(`Error: ${error.message}`);
      }

      console.log(errorMessage);
    }
  };

  const createPayload = (notification, clientName, apiUrl) => ({
    operation: "stop",
    device_id: notification?.device_id,
    processId: `${notification?.processId}`,
    db_name: clientName,
    startTime: notification?.startTime,
    endTime: notification?.endTime,
    apiUrl: apiUrl,
  });

  const executeFeatureRequest = async (featureName) => {
    switch (featureName) {
      case "anprbox":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.anprBox}`
          )
        );
      case "anpr":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.anpr}`
          )
        );
      case "inOut":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.inOutDetection}`
          )
        );
      case "inOutWithTime":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.inOutDetectionWithTime}`
          )
        );
      case "objectMovement":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.objectMovement}`
          )
        );
      case "objectMovementwithTime":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.momentWithTime}`
          )
        );
      case "detectPersonInsideTheBox":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.smartBoxPerson}`
          )
        );
      case "objectcount":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.storeData}`
          )
        );
      case "multipleObject":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.multipleObject}`
          )
        );
      case "customTrainModel":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.customTrainModel}`
          )
        );
      case "checkVehicleType":
        return api.post(
          `${BASE_URLS.stopProcessInEdge}`,
          createPayload(
            notification,
            clientName,
            `${pythonApiUrl}${BASE_URLS.checkVehicleType}`
          )
        );
      default:
        throw new Error(`Unsupported feature name: ${featureName}`);
    }
  };

  const isLatest = latestData?.id === notification.id;
  return (
    <div className={`notification ${isLatest ? "latestCon" : ""}`}>
      <div className={`notification-header `}>
        <span className={` ${isLatest ? "latest" : "notification-type"}`}>
          Device Id {notification.device_id} {isLatest ? "New" : ""}
        </span>
      </div>

      <div className="notification-body">
        <span className="configuration__status">
          {`Device NO ${notification.device_id} feature "${notification.featureName}" is "${notification.status}"`}
        </span>
        <span className="notification-message">
          ProcessId: {notification.processId}
        </span>

        <div className="notification-time">
          <div className="notification-start">
            <IoMdTime size={16} /> <strong>Start Time: </strong>
            {notification.startTime}
          </div>
          <div className="notification-start">
            <IoMdTime size={16} /> <strong>End Time: </strong>
            {notification.endTime}
          </div>
        </div>
      </div>

      <div className="notification-action">
        <button
          className="notification-button"
          onClick={() =>
            sendRequest(
              notification.processId,
              notification.featureName,
              notification,
              clientName
            )
          }
        >
          Terminate Process
        </button>
      </div>
    </div>
  );
};

export default NotificationCard;
