import React from "react";
// PrivateRoutes.js
import Dashboard from "../../pages/Dashboard/Dashboard";
import Device from "../../pages/Devices/Device";
import Report from "../../pages/Charts/Reports";
import NotificationPage from "../../pages/NotificationPage";
import AnprCount from "../../pages/FeatureMaping/AnprCount";
import FeatureMaping from "../../pages/FeatureMaping/Features";
import PeopleCount from "../../pages/FeatureMaping/PeopleCount";
import LiveView from "../../pages/LiveView/LiveView";
import ConfigPage from "../../pages/ConfigPage";
import Otd from "../../pages/FeatureMaping/Otd";
import SmsAlertManagment from "../../pages/SmsAlertSetting/SmsAlertManagment";
import LiveFeed from "../../pages/LiveView/LiveFeed";
import AnnotateLabel from "../../pages/AnnotateLabel/AnnotateLabel";
import Create from "../../pages/AnnotateLabel/Create";
import LatestAlert from "../../pages/Reports/LatestAlert";
import ImageAnotation from "../../pages/AnnotateLabel/ImageAnotation";
import AllAlerts from "../../pages/Reports/AllAlerts";
import ClientManagement from '../../pages/ClientManagment'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProcessHistory from "../../pages/Reports/ProcessHistory";
import MatchedPeople from "../../pages/Reports/PeopleCount/TotalPeopleCount";
import PeopleCountOut from "../../pages/Reports/PeopleCount/PeopleCountOut";
import TotalPeopleCount from "../../pages/Reports/PeopleCount/TotalPeopleCount";
import InPeopleCount from "../../pages/Reports/PeopleCount/InPeopleCount";
import Out2PeopleCount from "../../pages/Reports/PeopleCount/Out2PeopleCount";
import DashBoard1 from "../../pages/DashBoard1";


const clientRole = localStorage.getItem("role");

const PrivateRoutes = [
    {
      path: "/dashboard",
      element: <Dashboard  key={clientRole}/>,
      children: 
         [
            { index: true, element: <DashBoard1 /> },
            { path: "alertsetting", element: <SmsAlertManagment /> },
            { path: "live", element: <LiveView /> },
            { path: "videofeed", element: <LiveFeed /> },
            {
              path: "features",
              element: <FeatureMaping />,
              children: [
                { path: "objectcount", element: <PeopleCount /> },
                { path: "objectcount/:id", element: <ConfigPage /> },
                { path: "anpr", element: <AnprCount /> },
                { path: "anpr/:id", element: <ConfigPage /> },
                { path: "smartbox", element: <Otd /> },
                { path: "smartbox/:id", element: <ConfigPage /> },
              ],
            },
            { path: "Annotationandlabelling", element: <AnnotateLabel /> },
            { path: "build", element: <ImageAnotation /> },
            { path: "create", element: <Create /> },
            {
              path: "reports",
              children: [
                { path: "charts", element: <Report /> },
                { path: "livealerts", element: <LatestAlert /> },
                { path: "allalerts", element: <AllAlerts /> },
                { path: "processhistory", element: <ProcessHistory /> },
                { path: "totalpeoplecount", element: <TotalPeopleCount /> },
                { path: "inpeoplecount", element: <InPeopleCount /> },
                { path: "outpeoplecount", element: <PeopleCountOut /> },
                { path: "out2peoplecount", element: <Out2PeopleCount /> },

                { path: "peoplecountout", element: <PeopleCountOut />},
              ],
            },
            { path: "notification", element: <NotificationPage /> },
          ],
    },
  ];
 
  
  export default PrivateRoutes;

