import React from "react";
import "../../Assets/Styles/Product.css";
import productimg from "../../Assets/Images/Product.png";
import SMSimg from "../../Assets/Images/SMS.png";

const Product = () => {
  return (
    <div className="product">
      <div className="product__div1">
        <div className="product__div1--container">
        <div className="product__heading">
          <img src={productimg} alt="Logo" className="productimg" />
          <div className="product__content">
            <h2 className="product__h2 ">PMS:</h2>
            <p className="product__p intro">
              Our state-of-the-art Parking Management System transforms urban
              parking.
              <br />
              Together with smart barriers, Automatic Number Plate Recognition
              (ANPR) and RFID technologies provide seamless, ticketless entrance
              and departure.
              <br />
              We've launched an AI-integrated receipt generator that can
              function with a single hand gesture, removing the requirement for
              physical interactions, for a completely frictionless experience.
              <br />
              Our technology drives cars to the closest open space, reliably
              detects available parking places in real-time, and maximizes
              parking lot capacity by utilizing object identification and
              machine learning techniques.
            </p>
          </div>
        </div>
        <div className="product__ul">
          <li>
            <h3 className="product__h2">Advantages:</h3>
          </li>
          <ul className="product__points">
            <li> Increased revenue as a result of better space utilization</li>
            <li> Reduced emissions and traffic jams</li>
            <li>A better customer experience thanks to convenient parking</li>
            <li>Rich data insights for improved decision making.</li>
          </ul>
        </div>
        </div>
      </div>
      <div className="product__div2">
        <div className="div2__heading">
          <div className="div2__content">
            <h2 className="div2__h2">SMS:</h2>
            <p className="div2__p">
              A Society Management System is an all-inclusive software program
              created to facilitate residential community management.
              <br />
              It includes every element of community administration, including
              maintenance, communication, financial management, and security.
              <br />
              Improving residents' quality of life while making management
              committees' administrative tasks easier is the primary goal.
              <br />
              AI-integrated systems loaded with RFID, ANPR, boom barriers, IoT,
              and sensors that can detect and identify objects are at the front
              lines of this revolution.
            </p>
          </div>
          <img src={SMSimg} alt="Logo" className="SMSimg" />
        </div>
        <div className="div2__ul">
          <ul className="div2__points">
            <h3 className="div2__h2">Advantages:</h3>
            <li>Enhanced security and safety for all community members.</li>
            <li>Streamlined operations with fewer administrative overhead.</li>
            <li>
              Improved resident satisfaction thanks to proactive management.
            </li>
            <li>Significant cost savings through optimum resource usage.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Product;
