
import React, { useContext, useEffect, useState } from "react";
import "../..//Assets/Styles/dashboard.css";
import { Link, NavLink } from "react-router-dom";
import Profile from "../Profile";
import MobileSidebar from "../Utils/MobileSidebar";

import NotificationIcon from "../Notification/NotificationIcon";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = ({ toggleSidebar}) => {
  const [isSticky, setIsSticky] = useState(false);
  const clientRole = localStorage.getItem('role')
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 2) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="header-contaner">
      <div className={isSticky ? 'header sticky' : 'header'}>
        <div className="sidebar_button_containe">
          <button className="sidebar_button" onClick={toggleSidebar}>
            <GiHamburgerMenu className="hamBurger" />
          </button>
        </div>
        <h3 className="header-title"><span className="header-Subtitle">Buy</span>Ai<span className="header-Subtitle">Solution</span> </h3>
        <div className="header-list">
         { clientRole === 'user' && <NotificationIcon/>}
          <Profile />
           <MobileSidebar toggleSidebar={toggleSidebar}/>
        </div>
      </div>
    </div>
  );
};

export default Header;
