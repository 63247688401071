import React, { useEffect, useState } from "react";
import { TbDeviceImacStar } from "react-icons/tb";
import { NavLink, useLocation } from "react-router-dom";
import "../../../Assets/Styles/sidebar.css";

const AdminSidebar = ({clientRole}) => {
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    setActiveLink(pathArray[3]);
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 2) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  return (
    <>
      {clientRole === "admin" && (
        <div className={isSticky ? "navbar sticky" : "navbar"}>
          <div className="link-list">
            <NavLink
              to="/dashboard"
              className={`navLink ${
                activeLink === "dashboard" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("dashboard")}
            >
              <TbDeviceImacStar className="nav__Icon" /> User Management
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminSidebar;
